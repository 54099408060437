import { Tooltip } from '@bdo/kitchensink'
import { toLimitString } from 'utils/StringHelper/string-helper'
import clsx from 'clsx'
import { memo } from 'react'
import Icon from 'ui-components/Icon/Icon'
import styles from './GroupTabAnswers.module.scss'
import useQuestionnaireAnswerStore, { useGroup, useSelectedGroupKey } from '../questionAnswerZustandStore'

type Props = {
  groupKey: string
}

function GroupTabAnswers(props: Readonly<Props>) {
  const { groupKey } = props
  const selectGroup = useQuestionnaireAnswerStore((s) => s.selectGroup)
  const selectedKey = useSelectedGroupKey()
  const isSelected = selectedKey === groupKey
  const group = useGroup(groupKey)

  const onTabClick = (key: string) => {
    selectGroup(key)
  }

  return (
    <div className={styles.tabWrapper} key={groupKey}>
      <button
        type='button'
        onClick={() => {
          onTabClick(groupKey)
        }}
        className={clsx(isSelected ? styles.tabActive : '', styles.tab, 'flex', 'alignItems')}
      >
        <Tooltip title={group?.name} mouseEnterDelay={1}>
          <div className='flex alignItems'>
            <span>{toLimitString(group?.name, 40)}</span>
          </div>
        </Tooltip>
        <Icon type='Error' className={clsx(group?.hasErrors ? 'visible' : 'hidden', isSelected ? styles.acceptIcon : styles.errorIcon, 'redText')} />
        <Icon type='Accept' className={clsx(group?.hasErrors ? 'hidden' : 'visible', isSelected ? styles.acceptIcon : styles.acceptIconGray, 'redText')} />
      </button>
    </div>
  )
}

export default memo(GroupTabAnswers)
