import translate from 'i18n/translate'
import { useServices } from 'features/clients/clientZustandStore'
import { Application } from 'services/Client/client.models'
import clsx from 'clsx'
import styles from './TabServicesApps.module.scss'
import ServiceCard from './ServiceCard'
import AppWrapper from './AppWrapper'

function TabServicesApps() {
  const services = useServices()

  return (
    <div className={styles.pageWrapper}>
      <h3>{translate('services_and_apps')}</h3>
      <div className={clsx('flex', styles.serviceWrapper)}>
        {services.map((service) => (
          <ServiceCard key={service[0].id} name={service[0].service}>
            <>
              {service.map((app: Application) => (
                <AppWrapper key={app.id} appId={app.id} />
              ))}
            </>
          </ServiceCard>
        ))}
      </div>
    </div>
  )
}

export default TabServicesApps
