import clsx from 'clsx'
import { AnswerQuestionnaireStatus } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import useClientStore, { useLastAnsweredQuestionnaireData, usePublishedQuestionnaireData } from 'features/clients/clientZustandStore'
import PageNotification from 'ui-components/PageNotification/PageNotification'
import { useIntl } from 'react-intl'
import styles from './TabServicesApps.module.scss'
import PublishedTwinfield from './PublishedTwinfield'

type Props = {
  appId: number
}

export default function AppWrapper(props: Readonly<Props>) {
  const { appId } = props
  const intl = useIntl()
  const lastAnsweredQuestionnaireData = useLastAnsweredQuestionnaireData(appId)
  const publishedQuestionnaireData = usePublishedQuestionnaireData(appId)
  const appIdsWhereAnswersForImportAreAvailable = useClientStore((state) => state.appIdsWhereAnswersForImportAreAvailable)
  const isNewQuestionnairePublished = publishedQuestionnaireData?.status === AnswerQuestionnaireStatus.NotStarted
  const isLastAnsweredQuestionnaireAvailable = lastAnsweredQuestionnaireData !== null

  return (
    <>
      {isNewQuestionnairePublished && isLastAnsweredQuestionnaireAvailable && (
        <PageNotification
          notification={
            appIdsWhereAnswersForImportAreAvailable.includes(appId)
              ? intl.$t({ id: 'user_message_new_questionnaire_published' })
              : intl.$t({ id: 'user_message_new_questionnaire_published_no_relations' })
          }
          warningNotification={!appIdsWhereAnswersForImportAreAvailable.includes(appId)}
          infoNotification={appIdsWhereAnswersForImportAreAvailable.includes(appId)}
        />
      )}
      <div className={clsx(styles.singleAppWrapper)}>
        <PublishedTwinfield appId={appId} />
      </div>
    </>
  )
}
