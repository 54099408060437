import { Empty } from '@bdo/kitchensink'
import translate from '../../i18n/translate'
import styles from './TableEmptyState.module.scss'

function TableEmptyState() {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      imageStyle={{
        height: 60
      }}
      description={<p className={styles.emptyStateText}>{translate('empty_table')}</p>}
    />
  )
}

export default TableEmptyState
