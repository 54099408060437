import translate from 'i18n/translate'
import { Modal, Tooltip } from '@bdo/kitchensink'
import clsx from 'clsx'
import { useState } from 'react'
import { archiveQuestionnaire } from 'services/Questionnaire/questionnaire.service'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import Icon from 'ui-components/Icon/Icon'
import styles from './ArchiveAction.module.scss'

type Props = {
  name: string
  id: string
  onArchived: (id: string) => void
}

function ArchiveAction(props: Readonly<Props>) {
  const { name, id, onArchived } = props
  const [isConfirmationPopupOpened, setIsConfirmationPopupOpened] = useState<boolean>(false)

  const onArchivedConfirmed = () => {
    archiveQuestionnaire(id)
      .then(() => {
        setIsConfirmationPopupOpened(false)
        onArchived(id)
      })
      .catch((e) => {
        LoggerHelper(e)
      })
  }

  return (
    <>
      <Tooltip title={translate('button_archive')} mouseEnterDelay={0.5}>
        <button
          aria-label='button'
          onClick={() => setIsConfirmationPopupOpened(true)}
          type='button'
          className={clsx(styles.archiveButton, 'iconButton')}
          data-testid='archive-icon'
        >
          <Icon type='Archive' />
        </button>
      </Tooltip>
      <Modal
        style={{ whiteSpace: 'pre-line' }}
        title={translate('user_message_when_archive_questionnaire_question')}
        open={isConfirmationPopupOpened}
        onCancel={() => setIsConfirmationPopupOpened(false)}
        onOk={onArchivedConfirmed}
        okText={translate('button_archive')}
        cancelText={translate('button_cancel')}
      >
        {translate('user_message_when_archive_questionnaire_message', { name })}
      </Modal>
    </>
  )
}

export default ArchiveAction
