import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import '../../index.scss'
import { useMsal } from '@azure/msal-react'
import clsx from 'clsx'
import { appInformation } from 'app.config'
import Icon from 'ui-components/Icon/Icon'
import translate from '../../i18n/translate'
import styles from './Navigation.module.scss'

function Navigation() {
  const { instance } = useMsal()
  const [isSuperuser, setIsSuperuser] = useState<boolean>(false)
  const [isLedgerMenuOpen, setIsLedgerMenuOpen] = useState(true)
  const [isClicked, setIsClicked] = useState(true)

  useEffect(() => {
    const currentAccount = instance.getActiveAccount()
    if (currentAccount?.idTokenClaims && currentAccount.idTokenClaims.roles) {
      const { roles } = currentAccount.idTokenClaims
      setIsSuperuser(roles.includes('Superuser'))
    }
  })

  const toggleMenu = () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    setIsLedgerMenuOpen((isLedgerMenuOpen) => !isLedgerMenuOpen)
    setIsClicked(true)
  }

  return (
    <nav className={styles.navWrapper}>
      <div>
        <NavLink to='/clients' className={clsx(styles.link, 'navLink')} onClick={() => setIsClicked(false)}>
          <div className={styles.navItemWrapper}>
            <Icon type='BusinessCenterLogo' />
            {translate('clients')}
          </div>
        </NavLink>
        {isSuperuser && (
          <>
            <NavLink to='/questionnaires' className={clsx(styles.link, 'navLink')} onClick={() => setIsClicked(false)}>
              <div className={styles.navItemWrapper}>
                <Icon type='QuestionnaireMirrored' />
                {translate('questionnaires')}
              </div>
            </NavLink>
            <div>
              <button type='button' className={clsx(styles.ledgerToggle, 'flex', 'alignItems', !isClicked && styles.new)} onClick={toggleMenu}>
                <div className={clsx('flex', 'alignItems')}>
                  <Icon type='Dictionary' className={styles.dictionary} />
                  <span>{translate('account_scheme')}</span>
                </div>
                <Icon type='ChevronRight' className={clsx(styles.chevron, isLedgerMenuOpen ? styles.openChevron : styles.closedChevron)} />
              </button>
              <div className={clsx(styles.ledgerMenuWrapper, isLedgerMenuOpen ? 'visible' : 'hidden')}>
                <NavLink to='/generalLedger' className={clsx(styles.ledgerLink, 'ledgerLink')}>
                  <div>{translate('general_ledger')}</div>
                </NavLink>
                <NavLink to='/templates' className={clsx(styles.ledgerLink, 'ledgerLink')}>
                  <div>{translate('description_templates')}</div>
                </NavLink>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.versionInfo}>
        {translate('app_version')}: {appInformation.version}
      </div>
    </nav>
  )
}

export default Navigation
