import translate from 'i18n/translate'
import clsx from 'clsx'
import useClientStore, { useLastAnsweredQuestionnaireData } from 'features/clients/clientZustandStore'
import styles from './LastAnsweredQuestionnaire.module.scss'
import ModificationInfo from './ModificationInfo'
import AnswersStatusBadge from './AnswersStatusBadge'

type Props = {
  appId: number
}

function LastAnsweredQuestionnaire(props: Readonly<Props>) {
  const { appId } = props
  const lastAnsweredQuestionnaireData = useLastAnsweredQuestionnaireData(appId)
  const lastAnsweredQuestionnaireName = lastAnsweredQuestionnaireData.questionnaireName
  const lastAnsweredQuestionnaireStatus = lastAnsweredQuestionnaireData.status
  const appIdsWhereAnswersForImportAreAvailable = useClientStore((state) => state.appIdsWhereAnswersForImportAreAvailable)

  return (
    <div
      className={clsx(
        styles.lastAnsweredQuestionnaireInfo,
        'flex',
        'alignItems',
        !appIdsWhereAnswersForImportAreAvailable.includes(appId) ? styles.grayBackground : styles.greenBackground
      )}
    >
      <div className={styles.boxLabel}>{translate('user_message_prev_answered_questionnaire')}</div>

      <div className={styles.wrapper}>
        <div className={styles.nameWrapper}>
          <span className={styles.info}>Name:</span>
          <span className={styles.questionnaireName}>{lastAnsweredQuestionnaireName}</span>
        </div>

        <ModificationInfo modificationInfo={lastAnsweredQuestionnaireData} />
      </div>
      <div className={styles.status}>
        <AnswersStatusBadge questionnaireStatus={lastAnsweredQuestionnaireStatus} applicationName='' />
      </div>
    </div>
  )
}

export default LastAnsweredQuestionnaire
