import { Tooltip, Popover, Modal } from '@bdo/kitchensink'
import { toLimitString } from 'utils/StringHelper/string-helper'
import { LegacyRef, useCallback, useRef, useState } from 'react'
import clsx from 'clsx'
import { useGroup, useQuestionnaireStore, useSelectedGroupKey, useGroupHasErrors, useFormFieldIsReadOnly, useIsReadOnly } from 'features/questionnaire/questionaryZustandStore'
import EditValueModal from 'ui-components/EditValueModal/EditValueModal'
import { useIntl } from 'react-intl'
import translate from 'i18n/translate'
import { useDragDrop } from 'hooks/useDragDrop'
import { from } from 'linq-to-typescript'
import Icon from 'ui-components/Icon/Icon'
import styles from './GroupTab.module.scss'
import ConnectionWarningMessage from '../ConnectionWarningMessage/ConnectionWarningMessage'

type Props = {
  idx: number
  groupKey: string
}

function GroupTab(props: Readonly<Props>) {
  const { idx, groupKey } = props
  const intl = useIntl()
  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const group = useGroup(groupKey)
  const groupHasErrors = useGroupHasErrors(groupKey)
  const selectedKey = useSelectedGroupKey()
  const isSelected = selectedKey === groupKey
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [removeBtnIsDisabled, setRemoveBtnIsDisabled] = useState<boolean>(true)
  const formFieldIsReadOnly = useFormFieldIsReadOnly()
  const isPublishedQuestionnaire = useQuestionnaireStore((state) => state.isPublishedQuestionnaire)
  const isReadOnly = useIsReadOnly()
  const groups = useQuestionnaireStore((state) => state.questionnaireVM.groups)

  const swapGroups = useQuestionnaireStore((state) => state.swapGroups)
  const selectGroup = useQuestionnaireStore((state) => state.selectGroup)
  const updateGroup = useQuestionnaireStore((state) => state.updateGroup)
  const removeGroup = useQuestionnaireStore((state) => state.removeGroup)

  const handleOpenChange = () => {
    setOpen(!open)
  }

  const onRemoveGroup = () => {
    setIsModalOpen(true)
    setOpen(false)
  }

  const onUpdateGroup = (newName: string) => {
    const groupNameAlreadyExists = from(groups).any((x) => x.name.toLowerCase() === newName.toLowerCase()) && group?.name.toLowerCase() !== newName.toLowerCase()

    if (groupNameAlreadyExists) {
      return intl.$t({ id: 'message_error_group_name_exists' })
    }
    if (group) {
      updateGroup({ ...group, name: newName })
    }
    return ''
  }

  const contextMenuContent = (
    <div>
      <div className={clsx(isPublishedQuestionnaire ? 'mb-2' : '')}>
        <EditValueModal
          onConfirmEnteredValue={onUpdateGroup}
          closePopover={() => setOpen(false)}
          currentValue={group?.name}
          title={intl.$t({ id: 'question_group_edit_name' })}
          fieldName={intl.$t({ id: 'question_group_name' })}
          okText={intl.$t({ id: 'button_confirm' })}
          cancelText={intl.$t({ id: 'button_cancel' })}
          buttonText={intl.$t({ id: 'edit_name' })}
          simpleButton
        />
      </div>
      {!isPublishedQuestionnaire && (
        <button type='button' className={clsx(styles.deleteGroupButton)} onClick={onRemoveGroup}>
          <Icon type='Delete' />
          <span>{translate('question_group_delete')}</span>
        </button>
      )}

      <Modal
        style={{ whiteSpace: 'pre-line' }}
        title={translate('user_message_when_delete_group')}
        open={isModalOpen}
        onOk={() => removeGroup(group?.key)}
        okButtonProps={{ disabled: removeBtnIsDisabled, loading: removeBtnIsDisabled }}
        onCancel={() => setIsModalOpen(false)}
        okText={translate('button_delete')}
        cancelText={translate('button_cancel')}
      >
        <ConnectionWarningMessage
          filter={{ groupId: groupKey }}
          onConnectionsCheckFinished={() => setRemoveBtnIsDisabled(false)}
          warningMessageId='user_message_when_delete_group_condition_description_warning_message'
        />
        {translate('user_message_when_delete_group_message')}
      </Modal>
    </div>
  )

  const isDragging = useDragDrop(
    ref,
    (_, dragIndex, hoverIndex) => moveQuestionnaireGroupTab(dragIndex, hoverIndex),
    'item',
    idx,
    group?.key,
    isReadOnly || isPublishedQuestionnaire
  )
  const opacity = isDragging ? 0 : 1

  const onTabClick = (key: string) => {
    selectGroup(key)
  }

  const moveQuestionnaireGroupTab = useCallback((dragIndex: number, hoverIndex: number) => {
    swapGroups(dragIndex, hoverIndex)
  }, [])

  return (
    <div className={styles.tabWrapper} key={groupKey} data-testid='group-tab'>
      <button
        type='button'
        style={{ opacity }}
        ref={ref as unknown as LegacyRef<HTMLButtonElement> | undefined}
        onClick={() => {
          onTabClick(groupKey)
        }}
        className={clsx(isSelected ? styles.tabActive : '', styles.tab, 'flex', 'alignItems')}
      >
        <Tooltip title={group?.name} mouseEnterDelay={1}>
          <div className='flex alignItems'>
            {!isReadOnly && !isPublishedQuestionnaire && <Icon type='Waffle' className={styles.dragIcon} />}

            <span>{toLimitString(group?.name, 40)}</span>
          </div>
        </Tooltip>
        {groupHasErrors && !isSelected && <Icon type='Error' className={clsx(styles.errorIcon, 'redText')} />}
      </button>
      {!isReadOnly && !formFieldIsReadOnly && (
        <div className={styles.iconsSet} data-testid='contextMenu'>
          <Popover placement='right' content={contextMenuContent} trigger='click' open={open} onOpenChange={handleOpenChange}>
            <button type='button' aria-label='button' className={styles.contextMenuButton}>
              <Icon type='More' className={clsx(styles.moreIcon, isSelected ? styles.iconWhite : styles.iconColor)} style={{ display: isSelected ? 'block' : 'none' }} />
            </button>
          </Popover>
        </div>
      )}
    </div>
  )
}

export default GroupTab
