import { Modal } from '@bdo/kitchensink'
import { useOption, useQuestion, useQuestionnaireStore } from 'features/questionnaire/questionaryZustandStore'
import translate from 'i18n/translate'
import { useState } from 'react'
import ConnectionWarningMessage from '../ConnectionWarningMessage/ConnectionWarningMessage'

type Props = {
  questionKey: string
  optionKey: string
  isRemoveModalOpen: boolean
  setIsRemoveModalOpen: (isOpen: boolean) => void
}

export default function RemoveOptionModal(props: Readonly<Props>) {
  const { questionKey, optionKey, isRemoveModalOpen, setIsRemoveModalOpen } = props
  const removeOption = useQuestionnaireStore((state) => state.removeOption)
  const [removeOptionBtnIsDisabled, setRemoveOptionBtnIsDisabled] = useState<boolean>(true)
  const question = useQuestion(questionKey)
  const option = useOption(optionKey)
  const optionKeyFilter = option ? option.key : ''
  const isConnectedToSharedQuestions = question?.sharedQuestionsOptions.find((o) => o.optionId === option?.key) !== undefined

  return (
    <Modal
      style={{ whiteSpace: 'pre-line' }}
      title={translate('user_message_delete_options_question')}
      open={isRemoveModalOpen}
      onOk={() => {
        removeOption(option?.key)
        setIsRemoveModalOpen(false)
      }}
      onCancel={() => setIsRemoveModalOpen(false)}
      okText={translate('button_delete')}
      cancelText={translate('button_cancel')}
      okButtonProps={{ disabled: removeOptionBtnIsDisabled, loading: removeOptionBtnIsDisabled }}
    >
      <ConnectionWarningMessage
        filter={{ optionId: [optionKeyFilter] }}
        onConnectionsCheckFinished={() => setRemoveOptionBtnIsDisabled(false)}
        warningMessageId='user_message_when_delete_option_condition_warning_message'
      />
      {isConnectedToSharedQuestions && translate('user_message_delete_options_message')}
      {translate('user_message_when_delete_option_message')}
    </Modal>
  )
}
