import { useState } from 'react'
import translate from 'i18n/translate'
import { Form, Tooltip, Modal, Input } from '@bdo/kitchensink'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { cloneQuestionnaire } from 'services/Questionnaire/questionnaire.service'
import axios, { AxiosError } from 'axios'
import { ErrorModel } from 'services/Questionnaire/questionnaire.models'
import { useNavigate } from 'react-router-dom'
import Loader from 'ui-components/Loader/Loader'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import Icon from 'ui-components/Icon/Icon'
import styles from './DuplicateAction.module.scss'

type Props = {
  name: string
  id: string
}

function DuplicateAction(props: Readonly<Props>) {
  const { name, id: sourceQuestionnaireId } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCloning, setIsCloning] = useState(false)
  const [form] = Form.useForm()
  const intl = useIntl()
  const navigate = useNavigate()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = async () => {
    const clonedQuestionnaireName: string = form.getFieldValue('name')
    setIsModalOpen(false)
    setIsCloning(true)
    cloneQuestionnaire(sourceQuestionnaireId, clonedQuestionnaireName)
      .then((cloned) => {
        form.resetFields()

        navigate(`${cloned.id}/edit`)
      })
      .catch(onRequestError)
      .finally(() => setIsCloning(false))
  }

  const onRequestError = (e: Error | AxiosError) => {
    LoggerHelper(e)
    setIsModalOpen(true)
    if (axios.isAxiosError(e)) {
      if (e.response && e.response.status === 400) {
        const errors = e.response.data
        form.setFields([
          {
            name: 'name',
            touched: true,
            errors: errors.Name.map((error: ErrorModel) => intl.$t({ id: `api_${'name'}_${error.errorCode}` }))
          }
        ])
      }
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    form.resetFields()
  }

  return (
    <>
      <Tooltip title={translate('button_duplicate')} mouseEnterDelay={0.5}>
        <button type='button' aria-label='button' className={clsx(styles.duplicateButton, 'iconButton')} onClick={showModal} data-testid='duplicate-icon'>
          <Icon type='Copy' />
        </button>
      </Tooltip>
      <Modal
        title={translate('questionnaire_duplicate')}
        open={isModalOpen}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: isCloning }}
        onOk={form.submit}
        okButtonProps={{ loading: isCloning, disabled: isCloning }}
        okText={translate('button_duplicate')}
        cancelText={translate('button_cancel')}
      >
        <Form name='duplicate_questionnaire_form' form={form} layout='vertical' onFinish={handleOk}>
          <Form.Item name='name' initialValue={name} label={translate('questionnaire_new_name')} rules={[{ required: true, message: translate('forms_required_field') }]}>
            <Input placeholder={intl.$t({ id: 'forms_placeholder_no_characters' }, { value: '164' })} showCount maxLength={164} disabled={isCloning} />
          </Form.Item>
        </Form>
      </Modal>
      <Loader open={isCloning} text={intl.$t({ id: 'action_duplicating' })} />
    </>
  )
}

export default DuplicateAction
