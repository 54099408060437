import translate from 'i18n/translate'
import clsx from 'clsx'
import {
  useClientAddress,
  useClientCountry,
  useClientLegalForm,
  useClientName,
  useClientPostCode,
  useClientRelationNumber,
  useClientSbi
} from 'features/clients/clientZustandStore'
import styles from './TabClientDetails.module.scss'

function TabClientDetails() {
  const clientName = useClientName()
  const clientAddress = useClientAddress()
  const clientPostCode = useClientPostCode()
  const clientCountry = useClientCountry()
  const clientLegalForm = useClientLegalForm()
  const clientSbi = useClientSbi()
  const relationNumber = useClientRelationNumber()

  return (
    <div className={styles.wrapper}>
      <h3>{translate('client_details')}</h3>
      <div className={clsx(styles.clientDetailsWrapper, 'card', 'flex')}>
        <section className={styles.column}>
          <div className={styles.infoWrapper}>
            <span className={styles.label}>{translate('client_relation_number')}:</span>
            <span className={styles.info}>{relationNumber}</span>
          </div>
          <div className={styles.infoWrapper}>
            <span className={styles.label}>{translate('client_name')}:</span>
            <span className={styles.info}>{clientName}</span>
          </div>
          <div className={styles.infoWrapper}>
            <span className={styles.label}>{translate('client_address')}:</span>
            <span className={styles.info}>{clientAddress}</span>
          </div>
          <div className={styles.infoWrapper}>
            <span className={styles.label}>{translate('client_postcode')}:</span>
            <span className={styles.info}>{clientPostCode}</span>
          </div>
          <div className={styles.infoWrapper}>
            <span className={styles.label}>{translate('client_country')}:</span>
            <span className={styles.info}>{clientCountry}</span>
          </div>
        </section>

        <section className={styles.column}>
          <div className={styles.infoWrapper}>
            <span className={styles.label}>{translate('client_legal_form')}:</span>
            <span className={styles.info}>{clientLegalForm}</span>
          </div>
          <div className={styles.infoWrapper}>
            <span className={styles.label}>{translate('client_sbi_main_activity')}:</span>
            <span className={styles.info}>
              {clientSbi.description} - {clientSbi.id}
            </span>
          </div>
        </section>
      </div>
    </div>
  )
}

export default TabClientDetails
