import processingQuestionnaireTimeoutInMiliseconds from 'features/clientDetails/assets/timeoutDuration'
import moment from 'moment'

export type ClientQuestionnaireProcessingStatus = {
  clientId: number
  startTime?: string
  appId: number
  instanceId: string
}

export function markTaskCompletionAsStarted(clientId: number, appId: number, instanceId: string) {
  const processedQuestionnairesListFromLocalStorage = getProcessedQuestionnairesListFromLocalStorage()
  const newProcessedQuestionnaire: ClientQuestionnaireProcessingStatus = { clientId, startTime: moment().unix().toString(), appId, instanceId }
  const updatedProcessedQuestionnairesInLocalStorage = [...processedQuestionnairesListFromLocalStorage, newProcessedQuestionnaire]
  setLocalStorage(updatedProcessedQuestionnairesInLocalStorage)
}

export function processingFinished(clientId: number, appId: number, instanceId: string) {
  const processedQuestionnairesListFromLocalStorage = getProcessedQuestionnairesListFromLocalStorage()
  const updatedProcessedQuestionnairesInLocalStorage = processedQuestionnairesListFromLocalStorage.filter((item) => isItemWithCompletedStatus(item, clientId, appId, instanceId))
  setLocalStorage(updatedProcessedQuestionnairesInLocalStorage)
}

function isItemWithCompletedStatus(item: ClientQuestionnaireProcessingStatus, clientId: number, appId: number, instanceId: string) {
  if (clientId === item.clientId && appId === item.appId && instanceId === item.instanceId) {
    return clientId !== item.clientId && appId !== item.appId && instanceId !== item.instanceId
  }

  return true
}

export function isProcessing(clientId: number, appId: number, instanceId: string) {
  const processedQuestionnairesListFromLocalStorage = getProcessedQuestionnairesListFromLocalStorage()
  const processedQuestionnaire = processedQuestionnairesListFromLocalStorage.find((item) => item.clientId === clientId && item.appId === appId && item.instanceId === instanceId)

  if (!processedQuestionnaire) {
    return false
  }

  return (
    processedQuestionnaire?.clientId === clientId &&
    moment.unix(Number(processedQuestionnaire.startTime)).add(processingQuestionnaireTimeoutInMiliseconds, 'ms').unix() > moment().unix() &&
    processedQuestionnaire.appId === appId &&
    processedQuestionnaire.instanceId === instanceId
  )
}

export function getProcessedQuestionnairesListFromLocalStorage() {
  const localStorageProcessedQuestionnairesList = localStorage.getItem('OnProcessingQuestionnaireOnboardingTool')

  let allProcessedQuestionnaires: ClientQuestionnaireProcessingStatus[] = []
  if (localStorageProcessedQuestionnairesList) {
    allProcessedQuestionnaires = JSON.parse(localStorageProcessedQuestionnairesList)
  }
  return allProcessedQuestionnaires
}

export function setLocalStorage(content: ClientQuestionnaireProcessingStatus[]) {
  const localStorageProcessedQuestionnairesList = JSON.stringify(content)
  localStorage.setItem('OnProcessingQuestionnaireOnboardingTool', localStorageProcessedQuestionnairesList)
  window.dispatchEvent(new Event('storage'))
}
