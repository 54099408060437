import translate from 'i18n/translate'
import { Button, Message, Modal } from '@bdo/kitchensink'
import { useIntl } from 'react-intl'
import { useQuestionnaireId, useQuestionnaireStore, useQuestionnaireHasErrors, useIsDirty, useQuestionnaireApplicationName } from 'features/questionnaire/questionaryZustandStore'
import { getQuestionnaires, requestQuestionnairePublication, saveQuestionnaire } from 'services/Questionnaire/questionnaire.service'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Questionnaire, QuestionnaireStatuses } from 'services/Questionnaire/questionnaire.models'
import Loader from 'ui-components/Loader/Loader'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import Icon from 'ui-components/Icon/Icon'
import styles from './SaveAndPublish.module.scss'

type Props = {
  isLoading: boolean
}

function SaveAndPublish(props: Readonly<Props>) {
  const { isLoading } = props
  const intl = useIntl()
  const questionnaireHasErrors = useQuestionnaireHasErrors()
  const questionnaireId = useQuestionnaireId()
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isPublishing, setIsPublishing] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const isCheckingMultiplicationNumber = useQuestionnaireStore((state) => state.isCheckingMultiplicationNumber)
  const isReadOnly = useQuestionnaireStore((state) => state.isReadOnly)
  const isPublishedQuestionnaire = useQuestionnaireStore((state) => state.isPublishedQuestionnaire)
  const isDirty = useIsDirty()
  const setQuestionnaireVM = useQuestionnaireStore((state) => state.setQuestionnaireVM)
  const applicationName = useQuestionnaireApplicationName()
  const updateStatus = useQuestionnaireStore((state) => state.updateStatus)
  const updateFormFieldIsReadOnly = useQuestionnaireStore((state) => state.updateFormFieldIsReadOnly)
  const questionnaireVM = useQuestionnaireStore((state) => state.questionnaireVM)

  const onSaveDraft = () => {
    setIsSaving(true)
    updateFormFieldIsReadOnly(true)

    saveQuestionnaire(questionnaireVM)
      .then((savedQuestionnaire) => {
        setQuestionnaireVM(savedQuestionnaire)
        Message.success(intl.$t({ id: 'user_message_questionnaire_saved' }), 5)
      })
      .catch((error) => {
        LoggerHelper(error)
        Message.error(intl.$t({ id: 'user_message_questionnaire_save_error' }), 5)
      })
      .finally(() => {
        updateFormFieldIsReadOnly(false)
        setIsSaving(false)
      })
  }

  const onPublish = () => {
    getQuestionnaires(undefined, [QuestionnaireStatuses.Published])
      .then((data) => {
        const publishedQuestionnaire = data.find((item: Questionnaire) => item.application === applicationName)
        if (publishedQuestionnaire) {
          setIsModalOpen(true)
          // eslint-disable-next-line no-useless-return
          return
        }
        publishQuestionnaire()
      })
      .catch((e) => {
        LoggerHelper(e)
      })
  }

  const onPublishConfirm = () => {
    setIsModalOpen(false)
    publishQuestionnaire()
  }

  const publishQuestionnaire = () => {
    setIsPublishing(true)
    updateFormFieldIsReadOnly(true)
    requestQuestionnairePublication(questionnaireId)
      .then(() => {
        updateStatus(QuestionnaireStatuses.Published)
        Message.success(intl.$t({ id: 'user_message_questionnaire_saved' }), 5)
      })
      .catch((error) => {
        LoggerHelper(error)
        Message.error(intl.$t({ id: 'user_message_questionnaire_save_error' }), 5)
      })
      .finally(() => {
        setIsPublishing(false)
        updateFormFieldIsReadOnly(false)
      })
  }

  const onCloseClick = () => {
    navigate('/questionnaires')
  }

  return (
    <>
      {!questionnaireHasErrors && isDirty && (
        <span className={`${styles.unsavedChanges} flex alignItems`}>
          <Icon type='Error' /> {translate('user_message_questionnaire_unsaved_changes')}
        </span>
      )}
      {questionnaireHasErrors && (
        <span className={`${styles.unsavedChanges} flex alignItems redText`}>
          <Icon type='Error' className='redText' /> {translate('user_message_missing_info')}
        </span>
      )}

      {!isReadOnly ? (
        <>
          <Button
            onClick={onSaveDraft}
            type='primary'
            loading={isSaving}
            disabled={!isDirty || isSaving || questionnaireHasErrors || isLoading || isCheckingMultiplicationNumber}
            className='mr-2'
            data-testid='save-questionnaire'
          >
            {!isPublishedQuestionnaire ? translate('button_save_as_draft') : translate('button_save')}
          </Button>

          {!isPublishedQuestionnaire && (
            <Button type='default' onClick={onPublish} disabled={isDirty || isLoading || isSaving || isCheckingMultiplicationNumber}>
              {translate('button_publish')}
            </Button>
          )}
          <Modal
            style={{ whiteSpace: 'pre-line' }}
            title={translate('user_message_when_publish_question')}
            open={isModalOpen}
            onOk={onPublishConfirm}
            onCancel={() => setIsModalOpen(false)}
            okText={translate('button_publish')}
            cancelText={translate('button_cancel')}
          >
            {translate('user_message_when_publish_message')}
          </Modal>
        </>
      ) : (
        <Button onClick={onCloseClick}>{translate('button_close')}</Button>
      )}

      <Loader open={isSaving} text={intl.$t({ id: 'action_saving' })} />
      <Loader open={isPublishing} text={intl.$t({ id: 'action_publishing' })} />
    </>
  )
}

export default SaveAndPublish
