import { useState } from 'react'
import translate from 'i18n/translate'
import { Message, Modal, Tooltip } from '@bdo/kitchensink'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { useGeneralLedgerStore } from 'features/generalLedger/generalLedgerStore/generalLedgerStore'
import { deleteAccount } from 'services/generalLedger.service'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import Icon from 'ui-components/Icon/Icon'
import styles from './DeleteAction.module.scss'

type Props = {
  number: string
  id: number
}

function DeleteAction(props: Readonly<Props>) {
  const { number, id } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { removeAccount } = useGeneralLedgerStore()
  const intl = useIntl()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const onDeleteAccount = () => {
    deleteAccount(id)
      .then(() => {
        Message.success(intl.$t({ id: 'user_message_account_deleted' }), 5)
        removeAccount(id)
        setIsModalOpen(false)
      })
      .catch((error) => {
        Message.error(intl.$t({ id: 'user_message_account_deleted_error' }), 5)
        setIsModalOpen(false)
        LoggerHelper(error)
      })
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Tooltip title={translate('button_delete')} mouseEnterDelay={0.5}>
        <button onClick={showModal} type='button' aria-label='button' className={clsx(styles.deleteButton, 'iconButton')}>
          <Icon type='Delete' />
        </button>
      </Tooltip>

      <Modal
        style={{ whiteSpace: 'pre-line' }}
        title={translate('user_message_when_delete_account_question')}
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={onDeleteAccount}
        okText={translate('button_delete')}
        cancelText={translate('button_cancel')}
      >
        {translate('user_message_when_delete_account_message', { number })}
      </Modal>
    </>
  )
}

export default DeleteAction
