import { Button, Message, Modal } from '@bdo/kitchensink'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import translate from 'i18n/translate'
import clsx from 'clsx'
import { UploadFile } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import UploadIcon from 'assets/images/upload.svg'
import { RcFile } from 'antd/lib/upload'
import axios, { AxiosError, AxiosResponse } from 'axios'
import Loader from 'ui-components/Loader/Loader'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import Icon from 'ui-components/Icon/Icon'
import styles from './ImportButton.module.scss'

type ImportButtonProps = {
  onUpload: (file: RcFile) => Promise<AxiosResponse<unknown, unknown>>
  onFinished?: () => void
  modalTitle: string
}

function ImportButton(props: Readonly<ImportButtonProps>) {
  const { onUpload, onFinished, modalTitle } = props
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [file, setFile] = useState<UploadFile | undefined>(undefined)
  const [fileList, setFileList] = useState<UploadFile[]>()
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const intl = useIntl()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setFile(undefined)
    setError(false)
    setErrorMessage('')
    setFileList([])
  }

  const handleUpload = async () => {
    if (!file) {
      return
    }

    setIsProcessing(true)
    onUpload(file as RcFile)
      .then(async () => {
        Message.success(intl.$t({ id: 'import_success' }), 5)
        setIsModalOpen(false)
      })
      .catch((e) => {
        onRequestError(e)
        Message.error(intl.$t({ id: 'import_failed' }), 5)
      })
      .finally(() => {
        setIsProcessing(false)
        if (onFinished) {
          onFinished()
        }
      })
  }

  const onRequestError = (e: Error | AxiosError) => {
    LoggerHelper(e)
    if (axios.isAxiosError(e)) {
      if (e.response && e.response.status === 400) {
        setError(true)
        const responseErrorObject = e.response.data
        const errors = responseErrorObject[Object.keys(responseErrorObject)[0]]
        setErrorMessage(intl.$t({ id: `api_template_import_${errors[0].errorCode}` }, { value: errors[0].errorMessage }))
      }
    }
  }

  const onBeforeUpload = (fileToUpload: UploadFile) => {
    setFile(fileToUpload)
    setFileList([fileToUpload])
    return false
  }

  const onRemove = () => {
    setFile(undefined)
  }

  const onBrowse = () => {
    setError(false)
    setErrorMessage('')
  }

  return (
    <>
      <div className={styles.importButtonWrapper}>
        <Button className={clsx('flex', 'alignItems')} onClick={showModal}>
          <Icon type='CloudUpload' className='mr-1' />
          {translate('import')}
        </Button>
        <Modal
          title={modalTitle}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={[
            <Button key='back' onClick={handleCancel}>
              {translate('button_cancel')}
            </Button>
          ]}
        >
          <div className={styles.browserButtonWrapper}>
            <Dragger
              name='importFile'
              accept='.xlsx, .xls'
              data-testid='ant-Dragger'
              maxCount={1}
              multiple={false}
              beforeUpload={onBeforeUpload}
              disabled={isProcessing}
              onRemove={onRemove}
              fileList={fileList}
            >
              <img src={UploadIcon} alt='upload' />
              <h5>{translate('import_drag_drop_files_here')}</h5>
              <p>{intl.$t({ id: 'import_supported_files' }, { value: 'xlsx' })}</p>
              <Button type='primary' className={styles.browseButton} onClick={onBrowse}>
                {translate('button_browse')}
              </Button>
            </Dragger>
            <Button type='primary' className={styles.browseButton} onClick={handleUpload} disabled={isProcessing || file === undefined} loading={isProcessing}>
              {isProcessing ? <span>{translate('processing')}</span> : <span>{translate('start_import')}</span>}
            </Button>
            {error && <div className={styles.errorMessage}>{errorMessage}</div>}
          </div>
        </Modal>
      </div>
      <Loader open={isProcessing} text={intl.$t({ id: 'action_importing' })} />
    </>
  )
}

export default ImportButton
