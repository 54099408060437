import clsx from 'clsx'
import { useFormFieldIsReadOnly } from 'features/questionnaire/questionaryZustandStore'
import { memo } from 'react'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import Icon from 'ui-components/Icon/Icon'
import { Button } from '@bdo/kitchensink'
import styles from './AddButton.module.scss'

type Props = {
  handleAction: () => void
  children: JSX.Element
  disabled?: boolean
  size?: SizeType
}

function AddButton(props: Readonly<Props>) {
  const { handleAction, children, disabled, size } = props
  const formFieldIsReadOnly = useFormFieldIsReadOnly()

  return (
    <Button onClick={handleAction} type='default' size={size} className={clsx(styles.addButton, 'flex', 'alignItems')} disabled={disabled ?? formFieldIsReadOnly}>
      <Icon type='CirclePlus' />
      {children}
    </Button>
  )
}

export default memo(AddButton)
