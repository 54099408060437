import { useEffect, useState } from 'react'
import translate from 'i18n/translate'
import { Button, Form, Modal, Input, Select } from '@bdo/kitchensink'
import { useNavigate } from 'react-router-dom'
import { createQuestionnaire } from 'services/Questionnaire/questionnaire.service'
import { Apps, CreateQuestionnaire, ErrorModel, Services } from 'services/Questionnaire/questionnaire.models'
import { useIntl } from 'react-intl'
import axios, { AxiosError } from 'axios'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import { servicesAndApps } from 'configuration/servicesAndApps'

function AddNewModal() {
  const [form] = Form.useForm()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isFilled, setIsFilled] = useState(false)
  const [selectedService, setSelectedService] = useState<string>(Services.outsourcing)
  const navigate = useNavigate()
  const intl = useIntl()
  const { Option } = Select
  const servicesAndAppsInPopup = servicesAndApps

  const showModal = () => {
    setIsModalOpen(true)
  }

  useEffect(() => {
    if (!isModalOpen) {
      return
    }

    if (selectedService === Services.outsourcing) {
      form.setFields([{ name: 'application', value: Apps.twinfield }])
    }
  }, [selectedService])

  const handleOk = (values: CreateQuestionnaire) => {
    createQuestionnaire(values)
      .then((newQuestionnaire) => {
        setIsModalOpen(false)
        form.resetFields()

        const path = `${newQuestionnaire.id}/edit`
        navigate(path)
      })
      .catch(onRequestError)
      .finally(() => {
        setIsFilled(false)
      })
  }

  const onRequestError = (e: Error | AxiosError) => {
    LoggerHelper(e)
    if (axios.isAxiosError(e)) {
      if (e.response && e.response.status === 400) {
        const errors = e.response.data
        form.setFields([
          {
            name: 'name',
            touched: true,
            errors: errors.Name.map((error: ErrorModel) => intl.$t({ id: `api_${'name'}_${error.errorCode}` }))
          }
        ])
      }
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    form.resetFields()
    setIsFilled(false)
    setSelectedService(Services.outsourcing)
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFilled(true)
    if (e.target.value === '') {
      setIsFilled(false)
    }
  }

  return (
    <>
      <Button type='primary' onClick={showModal}>
        {translate('questionnaire_create')}
      </Button>
      <Modal
        title={translate('questionnaire_create')}
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form.submit}
        okButtonProps={{ disabled: !isFilled }}
        okText={translate('button_create')}
        cancelText={translate('button_cancel')}
      >
        <Form name='create_questionnaire_form' form={form} layout='vertical' onFinish={handleOk}>
          <Form.Item name='name' label={translate('questionnaire_name')} rules={[{ required: true, message: translate('forms_required_field') }]}>
            <Input placeholder={intl.$t({ id: 'forms_placeholder_no_characters' }, { value: '164' })} showCount maxLength={164} onChange={handleOnChange} />
          </Form.Item>
          <Form.Item initialValue={Services.outsourcing} name='service' label='Service' rules={[{ required: true, message: translate('forms_required_field') }]}>
            <Select onChange={(service) => setSelectedService(service)} data-testid='questionnaire_service'>
              {servicesAndAppsInPopup.map((service) => (
                <Option key={service.id} value={service.serviceName}>
                  {translate(`service_${service.serviceName.toLowerCase().replace(' ', '_')}`)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item initialValue={Apps.twinfield} name='application' label='Application' rules={[{ required: true, message: translate('forms_required_field') }]}>
            <Select data-testid='questionnaire_application'>
              {servicesAndAppsInPopup
                .filter((service) => service.serviceName === selectedService)
                .map((service) =>
                  service.apps.map((app) => (
                    <Option key={app.id} value={app.appName}>
                      {translate(`application_${app.appName}`)}
                    </Option>
                  ))
                )}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AddNewModal
