import { Button } from '@bdo/kitchensink'
import clsx from 'clsx'
import translate from 'i18n/translate'
import { useEffect, useState } from 'react'
import Icon from 'ui-components/Icon/Icon'
import useQuestionnaireAnswerStore, { useQuestionnaireGroups, useSelectedGroup } from '../questionAnswerZustandStore'
import styles from './GroupsNavigation.module.scss'

function GroupsNavigation() {
  const selectedGroup = useSelectedGroup()
  const groups = useQuestionnaireGroups()
  const { selectGroup } = useQuestionnaireAnswerStore()
  const [isFirstGroup, setIsFirstGroup] = useState<boolean>(false)
  const [isLastGroup, setIsLastGroup] = useState<boolean>(false)

  useEffect(() => {
    if (!selectedGroup) {
      return
    }
    if (groups.indexOf(selectedGroup) === groups.length - 1) setIsLastGroup(true)
    else setIsLastGroup(false)
    if (groups.indexOf(selectedGroup) === 0) setIsFirstGroup(true)
    else setIsFirstGroup(false)
  }, [selectedGroup])

  const showPreviousGroup = () => {
    if (!selectedGroup) {
      return
    }
    const prevGroupIndex = groups.indexOf(selectedGroup) - 1
    selectGroup(groups[prevGroupIndex].key)
  }

  const showNextGroup = () => {
    if (!selectedGroup) {
      return
    }
    const nextGroupIndex = groups.indexOf(selectedGroup) + 1
    selectGroup(groups[nextGroupIndex].key)
  }

  return (
    <div className={clsx(styles.navigationWrapper, 'flex', 'alignItems')}>
      <Button type='default' onClick={showPreviousGroup} disabled={isFirstGroup} className={clsx('flex', 'alignItems', isFirstGroup && 'visibilityHidden')}>
        <Icon type='ChevronLeft' />
        <span>{translate('nav_back')}</span>
      </Button>

      <Button type='default' onClick={showNextGroup} disabled={isLastGroup} className={clsx(styles.nextButton, 'flex', 'alignItems', isLastGroup && 'visibilityHidden')}>
        <span>{translate('nav_next')}</span>
        <Icon type='ChevronRight' />
      </Button>
    </div>
  )
}

export default GroupsNavigation
