import { AnswerQuestionnaireStatus, StatusColors } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import { Badge } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import { Apps } from 'services/Questionnaire/questionnaire.models'
import getStatusHint from 'utils/getStatusHint'
import Icon from 'ui-components/Icon/Icon'
import { Tooltip } from 'antd/lib'

type Props = {
  questionnaireStatus: AnswerQuestionnaireStatus
  customClassName?: string
  applicationName: Apps | string
}

export default function AnswersStatusBadge(props: Readonly<Props>) {
  const { questionnaireStatus, customClassName, applicationName } = props
  const indexOfS = Object.values(AnswerQuestionnaireStatus).indexOf(questionnaireStatus)
  const statusKey = Object.keys(AnswerQuestionnaireStatus)[indexOfS]
  const colorStatus = Object.values(StatusColors)[indexOfS]

  return (
    <span className='flex alignItems'>
      <Badge color={`${colorStatus}`} text={translate(`status_${statusKey}`)} className={customClassName} />
      {questionnaireStatus !== AnswerQuestionnaireStatus.NotStarted && applicationName && (
        <Tooltip title={getStatusHint(questionnaireStatus, applicationName)} mouseEnterDelay={0.1} placement='right'>
          <p>
            <Icon type='Unknown' className='hintIcon' />
          </p>
        </Tooltip>
      )}
    </span>
  )
}
