import translate from 'i18n/translate'
import clsx from 'clsx'
import { useState, useEffect } from 'react'
import { Form, Button, Message } from '@bdo/kitchensink'
import AccountForm from 'features/generalLedger/components/AccountForm/AccountForm'
import { saveAccount, LedgerAccount } from 'services/generalLedger.service'
import { useAccountStore } from 'features/generalLedger/accountStore/accountStore'
import { useParams } from 'react-router-dom'
import axios, { AxiosError } from 'axios'
import { ErrorModel } from 'services/Questionnaire/questionnaire.models'
import { useIntl } from 'react-intl'
import usePrompt from 'hooks/usePromptHook'
import Loader from 'ui-components/Loader/Loader'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import Icon from 'ui-components/Icon/Icon'
import styles from './AccountDetails.module.scss'

function AccountDetails() {
  const [form] = Form.useForm()
  const { setAccount, accountInfo, checkIsDirty, isDirty } = useAccountStore()
  const [isNumber, setIsNumber] = useState(true)
  const [formHasChangesOrIsNotFilled, setFormHasChangesOrIsNotFilled] = useState<boolean>(false)
  const { accountNumber, alwaysOn, dutchDescription, englishDescription, type } = accountInfo
  const { id } = useParams()
  const intl = useIntl()
  usePrompt(intl.$t({ id: 'user_message_unsaved_changes' }), isDirty)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  if (!id) {
    throw new Error(`Account id was not found`)
  }

  useEffect(() => {
    form.setFields([
      { name: 'id', value: id },
      { name: 'accountNumber', value: accountNumber },
      { name: 'type', value: type },
      { name: 'alwaysOn', value: alwaysOn },
      { name: 'dutchDescription', value: dutchDescription },
      { name: 'englishDescription', value: englishDescription }
    ])
  }, [accountInfo])

  const onUpdateAccount = (values: LedgerAccount) => {
    setIsSaving(true)
    const updatedAccount: LedgerAccount = { ...values }
    const accountId = Number(id)

    saveAccount({ ...updatedAccount, id: accountId })
      .then((savedAccount) => {
        setAccount(savedAccount)
        Message.success(intl.$t({ id: 'user_message_account_saved' }), 5)
      })
      .catch((e: Error | AxiosError) => {
        LoggerHelper(e)
        Message.error(intl.$t({ id: 'user_message_account_save_error' }), 5)
        if (axios.isAxiosError(e)) {
          if (e.response && e.response.status === 400) {
            const errors = e.response.data
            form.setFields([
              {
                name: 'accountNumber',
                touched: true,
                errors: errors.AccountNumber.map((error: ErrorModel) => intl.$t({ id: `api_${'account_number'}_${error.errorCode}` }))
              }
            ])
          }
        }
      })
      .finally(() => {
        setIsSaving(false)
        setFormHasChangesOrIsNotFilled(false)
      })
  }

  const onValuesChange = (values: LedgerAccount, allValues: LedgerAccount) => {
    checkIsDirty(values)

    if (allValues.accountNumber?.length < 7) {
      const isAccountNumberAStringValue = Number.isNaN(Number(allValues.accountNumber))
      setIsNumber(!isAccountNumberAStringValue)
      form.validateFields(['accountNumber']).catch(() => {
        setFormHasChangesOrIsNotFilled(false)
      })
    }

    const arrayContainsEmptyField = Object.values(allValues).some((x) => x === undefined || x === '')
    setFormHasChangesOrIsNotFilled(!arrayContainsEmptyField)
  }

  return (
    <>
      <div className={clsx(styles.detailsWrapper, 'card')}>
        <h3>{translate('account_details')}</h3>
        <div className={styles.formWrapper}>
          <Form name='add_account' form={form} layout='vertical' onFinish={onUpdateAccount} onValuesChange={onValuesChange}>
            <AccountForm />
          </Form>
        </div>
        <div className={clsx(styles.saveButtonWrapper, 'flex', 'alignItems')}>
          <Button type='primary' className={styles.saveButton} onClick={form.submit} loading={isSaving} disabled={!formHasChangesOrIsNotFilled || !isNumber}>
            {translate('button_save')}
          </Button>
          {isDirty && (
            <span className={`${styles.unsavedChanges} flex alignItems`}>
              <Icon type='Error' /> {translate('user_message_questionnaire_unsaved_changes')}
            </span>
          )}
        </div>
      </div>

      <Loader open={isSaving} text={intl.$t({ id: 'action_saving' })} />
    </>
  )
}

export default AccountDetails
