import { Checkbox, Tooltip } from '@bdo/kitchensink'
import { useOptions, useQuestionnaireStore } from 'features/questionnaire/questionaryZustandStore'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useState } from 'react'
import translate from 'i18n/translate'
import { from } from 'linq-to-typescript'
import { createOption } from 'utils/Create/CreateNewQuestionaAndOptionHelper'
import Icon from 'ui-components/Icon/Icon'
import styles from '../QuestionOpened/QuestionOpened.module.scss'
import { OptionViewModel } from '../_models/OptionViewModel'
import RemoveOptionModal from '../RemoveOptionModal/RemoveOptionModal'

type Props = {
  questionKey: string
  addNoneOption: (options: OptionViewModel[]) => void
}

export default function NoneOptionCheckbox(props: Readonly<Props>) {
  const { questionKey, addNoneOption } = props
  const isReadOnly = useQuestionnaireStore((state) => state.isReadOnly)
  const formFieldIsReadOnly = useQuestionnaireStore((state) => state.formFieldIsReadOnly)
  const isPublishedQuestionnaire = useQuestionnaireStore((state) => state.isPublishedQuestionnaire)
  const removeOption = useQuestionnaireStore((state) => state.removeOption)
  const options = useOptions(questionKey)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false)
  const isNoneOptionEnabled = from(options).any((x) => x.isNoneOption === true)

  const onNoneOptionChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      const noneOption: OptionViewModel = { ...createOption(), text: 'None', isNoneOption: true, hasValidationErrors: false }

      addNoneOption([noneOption, ...options])
    } else {
      removeNoneOption()
    }
  }

  function removeNoneOption() {
    if (options[0].text === '' || options[0].id === undefined) {
      removeOption(options[0].key)
    } else {
      setIsRemoveModalOpen(true)
    }
  }

  return (
    <>
      <Checkbox onChange={onNoneOptionChange} disabled={isReadOnly || formFieldIsReadOnly || isPublishedQuestionnaire} checked={isNoneOptionEnabled}>
        {translate('form_set_none_option')}
      </Checkbox>
      <Tooltip title={translate('form_set_none_option_hint')} mouseEnterDelay={0.1} placement='right'>
        <p>
          <Icon type='Unknown' className={styles.hintIcon} />
        </p>
      </Tooltip>
      <RemoveOptionModal questionKey={questionKey} optionKey={options[0].key} isRemoveModalOpen={isRemoveModalOpen} setIsRemoveModalOpen={setIsRemoveModalOpen} />
    </>
  )
}
