type OnboardingToolApiConfig = {
  uri: string | undefined
  scopes: string[] | undefined
  notificationHubUri: string | undefined
}

type AuthConfig = {
  clientId: string | undefined
  authority: string | undefined
  redirectUri: string | undefined
  logoutRedirect: string | undefined
  scopes: string[] | undefined
}

type GraphApiConfig = {
  uri: string | undefined
}

type AppInformation = {
  version: string | undefined
}

export const onboardingToolApiConfig: OnboardingToolApiConfig = {
  uri: process.env.REACT_APP_ONBOARDING_TOOL_API_URI,
  scopes: process.env.REACT_APP_ONBOARDING_TOOL_API_SCOPES?.split(';'),
  notificationHubUri: process.env.REACT_APP_ONBOARDING_TOOL_NOTIFICATIONHUB_URI
}

export const authConfig: AuthConfig = {
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  authority: process.env.REACT_APP_AUTH_AUTHORITY,
  redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
  logoutRedirect: process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI,
  scopes: process.env.REACT_APP_AUTH_LOGIN_REQUEST_SCOPES?.split(';')
}

export const graphApiConfig: GraphApiConfig = {
  uri: process.env.REACT_APP_GRAPH_ME_ENDPOINT
}

export const appInformation: AppInformation = {
  version: process.env.REACT_APP_VERSION
}
