/* eslint-disable consistent-return */
import translate from 'i18n/translate'
import clsx from 'clsx'
import { Form, Select, Checkbox, Message, Button, Radio, InputNumber, Tooltip } from '@bdo/kitchensink'
import { RadioChangeEvent } from 'antd'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import { toLimitString } from 'utils/StringHelper/string-helper'
import { getChildQuestions, getGroups, getQuestionnaires, getQuestions } from 'services/Questionnaire/questionnaire.service'
import { Apps, QuestionnaireStatuses, QuestionTypes } from 'services/Questionnaire/questionnaire.models'
import { saveConditions, getCondition, Condition as ConditionModel, updateConditions, deleteCondition } from 'services/accountCondition.service'
import { useParams } from 'react-router-dom'
import usePrompt from 'hooks/usePromptHook'
import {
  useAccountConditionStore,
  useSelectedRange,
  useGroups,
  useIsDirty,
  useOptions,
  useQuestionnaires,
  useSelectedQuestionId,
  useSelectedGroupId,
  useSelectedOptionId,
  useSelectedQuestionnaireId,
  useChildQuestions,
  useSelectedChildQuestionId,
  useQuestions,
  useAllAccountConditions,
  useCurrentCondition,
  useBooleanValue
} from 'features/generalLedger/accountConditionStore/accountConditionStore'
import { QuestionViewModel } from 'features/questionnaire/components/_models/QuestionViewModel'
import Loader from 'ui-components/Loader/Loader'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import Icon from 'ui-components/Icon/Icon'
import styles from './Condition.module.scss'

function Condition() {
  const { Option } = Select
  const [form] = Form.useForm()
  const intl = useIntl()
  const { id } = useParams()

  if (!id) {
    throw new Error(`Account id was not found`)
  }

  const accountId = Number.parseInt(id, 10)

  const setQuestionnaires = useAccountConditionStore((s) => s.setQuestionnaires)
  const setGroups = useAccountConditionStore((s) => s.setGroups)
  const setQuestions = useAccountConditionStore((s) => s.setQuestions)
  const setSelectedOption = useAccountConditionStore((s) => s.setSelectedOption)
  const selectQuestionnaire = useAccountConditionStore((s) => s.selectQuestionnaire)
  const selectGroup = useAccountConditionStore((s) => s.selectGroup)
  const selectQuestion = useAccountConditionStore((s) => s.selectQuestion)
  const selectChildQuestion = useAccountConditionStore((s) => s.selectChildQuestion)
  const setCurrentCondition = useAccountConditionStore((s) => s.setCurrentCondition)
  const setAllAccountConditions = useAccountConditionStore((s) => s.setAllAccountConditions)
  const setChildQuestions = useAccountConditionStore((s) => s.setChildQuestions)
  const setSelectedOptions = useAccountConditionStore((s) => s.setSelectedOptions)
  const addToAllAccountConditions = useAccountConditionStore((s) => s.addToAllAccountConditions)
  const updateAllAccountConditions = useAccountConditionStore((s) => s.updateAllAccountConditions)
  const deleteFromAllAccountConditions = useAccountConditionStore((s) => s.deleteFromAllAccountConditions)
  const cleanupCondition = useAccountConditionStore((s) => s.cleanupCondition)
  const setSelectedRange = useAccountConditionStore((s) => s.setSelectedRange)
  const setBooleanValue = useAccountConditionStore((s) => s.setBooleanValue)

  const selectedRange = useSelectedRange()
  const groups = useGroups()
  const options = useOptions()
  const isDirty = useIsDirty()
  const questionnaires = useQuestionnaires()
  const selectedQuestionnaireId = useSelectedQuestionnaireId()
  const selectedGroupId = useSelectedGroupId()
  const selectedQuestionId = useSelectedQuestionId()
  const selectedBooleanValue = useBooleanValue()
  const selectedChildQuestionId = useSelectedChildQuestionId()
  const selectedOptionIds = useSelectedOptionId()
  const parentQuestions = useQuestions()
  const parentQuestion = parentQuestions.find((q: QuestionViewModel) => q.id === selectedQuestionId)
  const childQuestions = useChildQuestions()
  const childQuestion = childQuestions.find((q: QuestionViewModel) => q.id === selectedChildQuestionId)
  const allAccountConditions = useAllAccountConditions()
  const currentCondition = useCurrentCondition()

  usePrompt(intl.$t({ id: 'user_message_unsaved_changes' }), isDirty)

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isQuestionnaireListLoading, setIsQuestionnaireListLoading] = useState<boolean>(false)
  const [isGroupListLoading, setIsGroupListLoading] = useState<boolean>(false)
  const [isQuestionListLoading, setIsQuestionListLoading] = useState<boolean>(false)
  const [booleanRadioButtonValue, setBooleanRadioButtonValue] = useState<boolean>()

  useEffect(() => {
    if (!parentQuestion?.multiplicationFactor) {
      return
    }
    if (parentQuestion?.type === QuestionTypes.Multiplied && !selectedRange?.max && !selectedChildQuestionId) {
      setSelectedRange({ min: 1, max: parentQuestion?.multiplicationFactor })
    } else if (selectedChildQuestionId !== undefined) {
      setSelectedRange(undefined)
    }
  }, [selectedQuestionId, selectedChildQuestionId])

  useEffect(() => {
    form.setFields([
      { name: 'rangeStart', value: selectedRange?.min },
      { name: 'rangeEnd', value: selectedRange?.max }
    ])
  }, [selectedRange])

  useEffect(() => {
    setBooleanRadioButtonValue(selectedBooleanValue)
  }, [selectedBooleanValue, currentCondition])

  useEffect(() => {
    setIsQuestionnaireListLoading(true)
    const getQuestionnairesPromise = getQuestionnaires(undefined, [QuestionnaireStatuses.Draft, QuestionnaireStatuses.Published], Apps.twinfield)
    const getConditionsPromise = getCondition(accountId)

    Promise.all([getQuestionnairesPromise, getConditionsPromise])
      .then(([questionnairesData, conditionsData]) => {
        if (conditionsData?.length) {
          setAllAccountConditions(conditionsData)
        }
        setQuestionnaires(questionnairesData)
        if (questionnairesData.length > 0) {
          selectQuestionnaire(questionnairesData[0].id)
        }
      })
      .catch((error) => {
        Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
        LoggerHelper(error)
      })
      .finally(() => {
        setIsQuestionnaireListLoading(false)
      })

    return cleanupCondition
  }, [])

  const loadCondition = (condition: ConditionModel) => {
    if (condition.range === null) {
      setCurrentCondition({ ...condition, range: undefined })
      setSelectedRange(undefined)
    } else {
      setCurrentCondition(condition)
    }
    selectQuestionnaire(condition.questionnaireId)
    selectGroup(condition.groupId)
    setBooleanValue(condition.booleanValue)
  }

  useEffect(() => {
    const foundQuestion = parentQuestions.find((q) => q.id === currentCondition.questionId)
    if (foundQuestion) {
      selectQuestion(currentCondition.questionId)
      setSelectedOptions(currentCondition.optionIds)
      if (currentCondition.range) {
        setSelectedRange({ min: currentCondition.range.min, max: currentCondition.range.max })
      } else {
        setSelectedRange(undefined)
      }
    } else if (!foundQuestion && selectedGroupId) {
      if (currentCondition.questionId === undefined) {
        selectQuestion(undefined)
        selectChildQuestion(undefined)
        setSelectedOptions([])
      } else {
        selectQuestion(currentCondition.parentQuestionId)
        selectChildQuestion(currentCondition.questionId)
        setSelectedOptions(currentCondition.optionIds)
        setBooleanValue(currentCondition.booleanValue)
      }
    }
  }, [parentQuestions])

  useEffect(() => {
    setCurrentCondition({ accountId: 0, groupId: undefined, questionId: undefined, questionnaireId: '', optionIds: [], parentQuestionId: undefined })
    setChildQuestions([])

    const questionnaireCondition = allAccountConditions.find((x) => x.questionnaireId === selectedQuestionnaireId)

    if (questionnaireCondition) {
      loadCondition(questionnaireCondition)
    }

    if (selectedQuestionnaireId) {
      form.setFields([{ name: 'questionnaire', value: selectedQuestionnaireId }])
      setIsGroupListLoading(true)
      getGroups(selectedQuestionnaireId)
        .then((data) => {
          setGroups(data)
        })
        .catch((error) => {
          Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
          LoggerHelper(error)
        })
        .finally(() => setIsGroupListLoading(false))
    }
  }, [selectedQuestionnaireId])

  useEffect(() => {
    if (groups.length !== 0) form.setFields([{ name: 'questionGroup', value: selectedGroupId }])
  }, [groups, selectedGroupId])

  useEffect(() => {
    if (selectedGroupId) {
      setIsQuestionListLoading(true)
      getQuestions(selectedGroupId, [QuestionTypes.Dropdown, QuestionTypes.Radio, QuestionTypes.Checkbox, QuestionTypes.Multiplied, QuestionTypes.Boolean])
        .then((questions) => {
          setQuestions(questions)
        })
        .catch((error) => {
          Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
          LoggerHelper(error)
        })
        .finally(() => setIsQuestionListLoading(false))
    }
  }, [selectedGroupId])

  useEffect(() => {
    if (selectedQuestionId && parentQuestion?.type === QuestionTypes.Multiplied) {
      getChildQuestions(selectedQuestionId)
        .then((selectedParentQuestion) => {
          const questionsWithOptions = selectedParentQuestion.generatedQuestions.filter(
            (q) => q.type === QuestionTypes.Checkbox || q.type === QuestionTypes.Dropdown || q.type === QuestionTypes.Radio
          )
          setChildQuestions(questionsWithOptions)
        })
        .catch((error) => {
          Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
          LoggerHelper(error)
        })
    }
  }, [selectedQuestionId])

  useEffect(() => {
    const fields = [
      { name: 'question', value: selectedQuestionId },
      { name: 'childQuestion', value: undefined }
    ]

    options.forEach((opt) => {
      fields.push({ name: opt.key, value: undefined })
    })
    if (currentCondition.questionId === selectedQuestionId) {
      currentCondition.optionIds.forEach((opt: string) => {
        fields.push({ name: opt, value: 'checked' })
      })
    }

    form.setFields(fields)

    if (selectedQuestionId && parentQuestion?.type === QuestionTypes.Multiplied) {
      getChildQuestions(selectedQuestionId)
        .then((selectedParentQuestion) => {
          const childGeneratorQuestions = selectedParentQuestion.generatedQuestions.filter(
            (q) => q.type === QuestionTypes.Checkbox || q.type === QuestionTypes.Dropdown || q.type === QuestionTypes.Radio
          )
          setChildQuestions(childGeneratorQuestions)
        })
        .catch((error) => {
          Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
          LoggerHelper(error)
        })
    }
  }, [parentQuestion])

  useEffect(() => {
    const fields = [{ name: 'childQuestion', value: selectedChildQuestionId }]

    options.forEach((opt) => {
      fields.push({ name: opt.key, value: undefined })
    })
    if (currentCondition.questionId === selectedChildQuestionId) {
      currentCondition.optionIds.forEach((opt: string) => {
        fields.push({ name: opt, value: 'checked' })
      })
    }

    form.setFields(fields)
  }, [selectedChildQuestionId])

  const onOptionChange = (checked: boolean, optionId: string) => {
    setSelectedOption(optionId, checked)
  }

  const onBooleanValueChange = (e: RadioChangeEvent) => {
    setBooleanRadioButtonValue(e.target.value)
    setBooleanValue(e.target.value)
  }

  const deselectOnClick = (e: React.MouseEvent<HTMLElement>) => {
    const { value } = e.target as HTMLInputElement
    if (value === String(booleanRadioButtonValue)) {
      setBooleanValue(undefined)
      setBooleanRadioButtonValue(undefined)
    }
  }

  const onSubmit = () => {
    if (!selectedQuestionnaireId) {
      return
    }

    if (!selectedGroupId || !selectedQuestionId || (selectedOptionIds.length === 0 && selectedRange === undefined && selectedBooleanValue === undefined)) {
      setIsSaving(true)
      deleteCondition(accountId, selectedQuestionnaireId)
        .then(() => {
          deleteFromAllAccountConditions(accountId, selectedQuestionnaireId)
          setCurrentCondition({
            accountId,
            groupId: undefined,
            optionIds: [],
            questionId: undefined,
            questionnaireId: selectedQuestionnaireId
          })
          Message.success(intl.$t({ id: 'user_message_condition_removed' }))
        })
        .catch((error) => {
          Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
          LoggerHelper(error)
        })
        .finally(() => setIsSaving(false))
    } else {
      const hasQuestionnaireCondition = allAccountConditions.find((x) => x.questionnaireId === selectedQuestionnaireId) !== undefined
      const conditionToSave: ConditionModel = {
        accountId,
        groupId: selectedGroupId,
        questionId: selectedChildQuestionId ?? selectedQuestionId,
        questionnaireId: selectedQuestionnaireId,
        optionIds: selectedRange || selectedBooleanValue ? [] : selectedOptionIds,
        range: selectedRange,
        parentQuestionId: selectedQuestionId,
        booleanValue: selectedBooleanValue
      }
      if (hasQuestionnaireCondition) {
        setIsSaving(true)
        updateConditions(accountId, conditionToSave)
          .then((data) => {
            updateAllAccountConditions(data)
            setCurrentCondition(data)
            Message.success(intl.$t({ id: 'user_message_condition_saved' }))
          })
          .catch((error) => {
            Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
            LoggerHelper(error)
          })
          .finally(() => setIsSaving(false))
      } else {
        setIsSaving(true)
        saveConditions(accountId, conditionToSave)
          .then((data) => {
            addToAllAccountConditions(data)
            setCurrentCondition(data)
            Message.success(intl.$t({ id: 'user_message_condition_saved' }))
          })
          .catch((error) => {
            Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
            LoggerHelper(error)
          })
          .finally(() => setIsSaving(false))
      }
    }
  }

  const onClearBtnClick = () => {
    setSelectedOptions([])
    const fields: { name: string; value: string | undefined }[] = []
    options.forEach((opt) => {
      fields.push({ name: opt.key, value: undefined })
    })
    form.setFields(fields)
    selectQuestion(undefined)
    setQuestions([])
    selectGroup(undefined)
    setChildQuestions([])
    selectChildQuestion(undefined)
    setBooleanValue(undefined)
  }

  const onRangeStartChange = (min: number | null) => {
    if (!selectedRange?.max || !min) {
      return
    }
    setSelectedRange({ min, max: selectedRange.max })
  }

  const onRangeEndChange = (max: number | null) => {
    if (!selectedRange?.min || !max) {
      return
    }
    setSelectedRange({ min: selectedRange.min, max })
  }

  const onClearChildQuestionSelectField = () => {
    selectChildQuestion(undefined)
  }
  return (
    <>
      <Form onFinish={onSubmit} form={form} layout='vertical' className={clsx(styles.conditionForm)}>
        <div className='card'>
          <div className={styles.conditionWrapper}>
            <h3>{translate('account_condition')}</h3>

            <div className={clsx(styles.wrapper, 'flex')}>
              <Form.Item name='questionnaire' label={translate('questionnaire')} className={styles.selectField}>
                <Select
                  showSearch
                  data-testid='questionnaire_select'
                  onChange={selectQuestionnaire}
                  placeholder={intl.$t({ id: 'questionnaire_select' })}
                  loading={isQuestionnaireListLoading}
                  filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())}
                >
                  {questionnaires.map((q) => (
                    <Option value={q.id} label={q.name} key={q.id} className='flagInDropdown'>
                      <Tooltip title={q.name} mouseEnterDelay={1}>
                        <span>
                          {toLimitString(
                            `${q.name} (${intl.$t({
                              id: `questionnaire_${q.status.toLowerCase()}`
                            })}) `,
                            60
                          )}
                        </span>
                      </Tooltip>
                      {allAccountConditions.findIndex((x) => x.questionnaireId === q.id) !== -1 ? (
                        <span className={clsx(styles.conditionFlag, 'flag')}>{translate('account_condition')}</span>
                      ) : null}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <div className={styles.clearButton}>
                <Button onClick={onClearBtnClick} type='default'>
                  {translate('button_clear_condition')}
                </Button>
              </div>

              <Form.Item name='questionGroup' label={translate('question_group')} className={styles.selectField}>
                <Select
                  showSearch
                  onChange={selectGroup}
                  placeholder={intl.$t({ id: 'question_group_select' })}
                  data-testid='question_group_select'
                  loading={isGroupListLoading}
                  filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())}
                >
                  {groups.map((q) => (
                    <Option value={q.id} label={q.name} key={q.id}>
                      <span className={q.name.replace(/\s/g, '')}>{q.name}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name='question' label={translate('question')} className={styles.selectField}>
                <Select
                  showSearch
                  onChange={selectQuestion}
                  placeholder={intl.$t({ id: 'question_select' })}
                  data-testid='question_select'
                  loading={isQuestionListLoading}
                  filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())}
                >
                  {parentQuestions.map((q) => (
                    <Option value={q.key} label={q.text} key={q.id}>
                      <span className={q.text.replace(/\s/g, '')}>{q.text}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {parentQuestion?.type === QuestionTypes.Multiplied && parentQuestion.generatedQuestions.length === 0 && (
                <Form.Item
                  name='childQuestion'
                  label={translate('question_child')}
                  help={childQuestion ? '' : translate('user_info_child_question_in_account_settings')}
                  className={styles.selectField}
                >
                  <Select
                    showSearch
                    onChange={selectChildQuestion}
                    onClear={onClearChildQuestionSelectField}
                    placeholder={intl.$t({ id: 'question_select' })}
                    data-testid='generated_question_select'
                    loading={isQuestionListLoading}
                    allowClear
                    filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())}
                  >
                    {childQuestions.map((q) => (
                      <Option value={q.key} label={q.text} key={q.id}>
                        <span className={q.text.replace(/\s/g, '')}>{q.text}</span>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </div>
          </div>
        </div>
        <div className='card mt-2'>
          <div className={clsx(styles.conditionWrapper)}>
            {parentQuestion?.type === QuestionTypes.Multiplied && !selectedChildQuestionId && (
              <>
                <h5>{translate('user_message_set_account_active_condition_with_generator')}</h5>
                {translate('user_message_set_account_active_condition_with_generator_options')} 1-{parentQuestion?.multiplicationFactor}
                <div className={clsx(styles.rangeWrapper, 'flex', 'alignItems')}>
                  <div className={styles.rangeField}>
                    <Form.Item
                      name='rangeStart'
                      rules={[
                        {
                          required: true,
                          message: translate('forms_required_field')
                        }
                      ]}
                    >
                      <InputNumber type='number' value={selectedRange?.min} min={1} max={selectedRange?.max} onChange={onRangeStartChange} data-testid='min_range' />
                    </Form.Item>
                  </div>
                  <div className={styles.dash}>-</div>
                  <div className={styles.rangeField}>
                    <Form.Item
                      name='rangeEnd'
                      rules={[
                        {
                          required: true,
                          message: translate('forms_required_field')
                        }
                      ]}
                    >
                      <InputNumber
                        value={selectedRange?.max}
                        min={selectedRange?.min}
                        max={parentQuestion.multiplicationFactor}
                        onChange={onRangeEndChange}
                        data-testid='max_range'
                        type='number'
                      />
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
            {parentQuestion?.type === QuestionTypes.Boolean && (
              <Form.Item valuePropName='checked' name='true' key='true' className='conditionOption' initialValue={booleanRadioButtonValue}>
                <Radio.Group onChange={(e) => onBooleanValueChange(e)} value={booleanRadioButtonValue}>
                  <Radio value onClick={deselectOnClick} className={styles.radioButton} data-testid='radio_boolean_value'>
                    True
                  </Radio>
                  <Radio value={false} onClick={deselectOnClick} className={styles.radioButton}>
                    False
                  </Radio>
                </Radio.Group>
              </Form.Item>
            )}
            {options.length > 0 && <h5>{translate('user_message_set_account_active_condition')}</h5>}
            {options.map((o) => (
              <Form.Item valuePropName='checked' name={o.id} key={o.id} className='conditionOption'>
                <Checkbox onChange={(e) => onOptionChange(e.target.checked, o.key)}>{o.text}</Checkbox>
              </Form.Item>
            ))}
            <div className={clsx(styles.saveButtonWrapper, 'flex', 'alignItems')}>
              <Button
                type='primary'
                className={styles.saveButton}
                onClick={form.submit}
                loading={isSaving}
                disabled={
                  isSaving || !isDirty || !(selectedOptionIds?.length === 0 || selectedGroupId !== undefined || selectedRange === undefined || selectedBooleanValue === undefined)
                }
              >
                {translate('button_save')}
              </Button>
              {isDirty && (
                <span className={`${styles.unsavedChanges} flex alignItems`}>
                  <Icon type='Error' /> {translate('user_message_questionnaire_unsaved_changes')}
                </span>
              )}
            </div>
          </div>
        </div>
      </Form>
      <Loader open={isSaving} text={intl.$t({ id: 'action_saving' })} />
    </>
  )
}

export default Condition
