// eslint-disable-next-line import/no-cycle
import { QuestionViewModel } from 'features/questionnaire/components/_models/QuestionViewModel'
import { GeneratorRange } from 'services/accountCondition.service'

export type CreateQuestionnaire = {
  name: string
  service: string
  application: string
}

export interface Questionnaire {
  id: string
  name: string
  status: string
  modifiedDate: Date
  modifiedBy: string
  service: Services
  application: Apps
  groups: Group[]
}

export interface Group {
  id?: string
  name: string
  numberOfColumns: number
  columns: Column[]
}

export type Column = {
  id?: string
  name?: string
  isDeleted: boolean
  questions: Question[]
}

export type Question = {
  id?: string
  type: keyof typeof QuestionTypes
  text: string
  tip: string
  isMandatory: boolean
  defaultValue?: string
  maxNumberOfCharacters?: number
  options: Option[]
  multiplicationFactor?: number
  generatedQuestions: QuestionViewModel[]
  questionsTemplate: QuestionViewModel[]
  sharedQuestions: QuestionViewModel[]
  sharedQuestionsOptions: SharedQuestionsOptions[]
}

export type Option = {
  id?: string
  text: string
  isDefault: boolean
  isNoneOption: boolean
}

export type SharedQuestionsOptions = { optionId: string; sharedQuestionId: string }

export enum QuestionTypes {
  Text = 'Text',
  Number = 'Number',
  Date = 'Date',
  Radio = 'Radio',
  Checkbox = 'Checkbox',
  Dropdown = 'Dropdown',
  Multiplied = 'Multiplied',
  Boolean = 'Boolean'
}

export type ErrorModel = {
  errorCode: string
  errorMessage: string
}

export enum QuestionnaireStatuses {
  Draft = 'Draft',
  Published = 'Published',
  Archived = 'Archived'
}

export type QuestionAccountConnection = {
  questionnaireId: string
  questionId: string
  connectedObjectId: string
  range: GeneratorRange
  accountId: string
}

export enum Services {
  outsourcing = 'Outsourcing'
}

export enum Apps {
  twinfield = 'Twinfield'
}
