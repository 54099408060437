/* eslint-disable radix */
import AccountTab from 'features/generalLedger/components/AccountTab/AccountTab'
import TopBar from 'ui-components/TopBar/TopBar'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAccountStore } from 'features/generalLedger/accountStore/accountStore'
import { getAccount } from 'services/generalLedger.service'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import translate from 'i18n/translate'
import { Button, Message } from '@bdo/kitchensink'
import Loader from 'ui-components/Loader/Loader'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import styles from './Account.module.scss'

function Account() {
  const { setAccount, accountInfo } = useAccountStore()
  const { accountNumber, type, alwaysOn } = accountInfo
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const intl = useIntl()
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    setIsDisabled(alwaysOn)
  }, [alwaysOn])

  if (!id) {
    throw new Error(`Account id was not found`)
  }

  useEffect(() => {
    if (!id) {
      return
    }
    setIsLoading(true)
    const idN = Number.parseInt(id)
    getAccount(idN)
      .then((data) => {
        setAccount(data)
      })
      .catch((error) => {
        LoggerHelper(error)
        Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }), 5)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <>
      <TopBar
        topBarData={{
          pageName: accountNumber,
          icon: 'Dictionary',
          backLink: '/generalLedger',
          backLinkPageNameTranslation: intl.$t({ id: 'general_ledger' }),
          info1: `${intl.$t({ id: 'account_type_info' })}: ${type}`
        }}
      >
        <Button onClick={() => navigate('/generalLedger')}>{translate('button_close')}</Button>
      </TopBar>

      <div className={`${styles.contentWrapper} flex`}>
        <div className={styles.accountTabs}>
          <AccountTab label={intl.$t({ id: 'account_details' })} handleAction={() => navigate('account')} isSelected={location.pathname.includes('account')} />
          <AccountTab
            label={intl.$t({ id: 'account_condition' })}
            handleAction={() => navigate('condition')}
            isSelected={location.pathname.includes('condition')}
            disabled={isDisabled}
          />
          <AccountTab
            label={intl.$t({ id: 'account_customer_description' })}
            handleAction={() => navigate('description')}
            isSelected={location.pathname.includes('description')}
            disabled={isDisabled}
          />
        </div>
        <div className={styles.accountContent}>
          <Outlet />
        </div>
      </div>
      <Loader open={isLoading} text={intl.$t({ id: 'action_loading' })} />
    </>
  )
}

export default Account
