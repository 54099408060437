import translate from 'i18n/translate'
import { Table } from '@bdo/kitchensink'
import { TablePaginationConfig } from '@bdo/kitchensink/lib/components/table/Table'
import TableEmptyState from 'ui-components/TableEmptyState/TableEmptyState'
import { useTemplatesStore } from 'features/generalLedger/TemplatesStore/TemplatesStore'
import { Template } from 'services/templates.service'
import TemplateEdit from '../TemplateEdit/TemplateEdit'
import TemplateDelete from '../TemplateDelete/TemplateDelete'

type Props = {
  isLoading: boolean
}

function TemplatesList(props: Readonly<Props>) {
  const { isLoading } = props
  const { templates, page, pageSize, total, setPagination } = useTemplatesStore()
  const columns = [
    {
      title: translate('description_template'),
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => <span className='noSpacesText'>[{name.trim()}]</span>
    },
    {
      title: translate('actions'),
      width: 120,
      render: (item: Template) => (
        <>
          <TemplateEdit id={item.id} name={item.name} />
          <TemplateDelete id={item.id} name={item.name} />
        </>
      )
    }
  ]

  const onTableChange = (pagination: TablePaginationConfig) => {
    if (pagination.current && pagination.pageSize && pagination.total) {
      setPagination(pagination.current, pagination.pageSize, total)
    }
  }

  return (
    <Table
      dataSource={templates}
      columns={columns}
      rowKey='id'
      pagination={{
        current: page,
        pageSize,
        total,
        hideOnSinglePage: true
      }}
      loading={isLoading}
      onChange={onTableChange}
      locale={{
        emptyText: <TableEmptyState />
      }}
      id='ClientTable'
    />
  )
}

export default TemplatesList
