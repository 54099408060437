import { useState, useEffect } from 'react'
import { Message, Table } from '@bdo/kitchensink'
import { SorterResult, TablePaginationConfig, TableProps } from '@bdo/kitchensink/lib/components/table/Table'
import { getClients } from 'services/Client/client.service'
import { ClientListItem } from 'services/Client/client.models'
import translate from 'i18n/translate'
import TableEmptyState from 'ui-components/TableEmptyState/TableEmptyState'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { useIntl } from 'react-intl'
import SearchField from 'ui-components/SearchField/SearchField'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import getSorterTooltipText from 'utils/TooltipTextHelper/tooltipTextHelper'
import { useClientListStore } from '../../clientListStore'

function ClientsList() {
  const intl = useIntl()
  const [clients, setClients] = useState<ClientListItem[]>([])
  const [isLoading, setIsLoading] = useState<boolean>()

  const setOrder = useClientListStore((state) => state.setOrder)
  const resetSortOrder = useClientListStore((state) => state.resetSortOrder)
  const setPagination = useClientListStore((state) => state.setPagination)
  const setSearchValue = useClientListStore((state) => state.setSearchValue)
  const sortField = useClientListStore((state) => state.sortField)
  const sortDirection = useClientListStore((state) => state.sortDirection)
  const total = useClientListStore((state) => state.total)
  const pageSize = useClientListStore((state) => state.pageSize)
  const page = useClientListStore((state) => state.page)
  const searchValue = useClientListStore((state) => state.searchValue)
  const sortQueryString = sortDirection === 'ascend' ? `${sortField}` : `${sortField} desc`

  const columns = [
    {
      title: translate('client'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortField === 'name' ? sortDirection : undefined,
      render: (name: string, record: ClientListItem) => <NavLink to={`${record.id}/services_apps`}>{name}</NavLink>
    },
    {
      title: translate('client_relation_number'),
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      sortOrder: sortField === 'id' ? sortDirection : undefined
    },
    {
      title: translate('table_column_modification_date'),
      dataIndex: 'modificationDate',
      key: 'modificationDate',
      sorter: true,
      sortOrder: sortField === 'modificationDate' ? sortDirection : undefined,
      render: (modifiedDate: Date | null) => (modifiedDate !== null ? moment.utc(modifiedDate).local().format('DD.MM.YYYY HH:mm') : '')
    }
  ]

  useEffect(() => {
    fetchClients()
  }, [page, pageSize, searchValue, sortQueryString])

  const fetchClients = () => {
    setIsLoading(true)

    getClients(page, pageSize, searchValue, sortQueryString)
      .then((result) => {
        setPagination(page, pageSize, result.total)
        setClients(result.rows)
        showFeedbackAfterSearch(result.rows.length)
      })
      .catch((error) => {
        LoggerHelper(error)
        Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
      })
      .finally(() => setIsLoading(false))
  }

  const showFeedbackAfterSearch = (length: number) => {
    if (searchValue !== '' && length > 0) {
      Message.success(intl.$t({ id: 'user_message_client_search' }))
    } else if (length === 0) {
      Message.error(intl.$t({ id: 'user_message_client_search_no_results' }))
    }
  }

  const onSearchChanged = (value: string) => {
    if (value === '') {
      resetSortOrder()
    }
    setPagination(1, pageSize, total)
    setSearchValue(value)
  }

  const onChange: TableProps<ClientListItem>['onChange'] = (pagination: TablePaginationConfig, _, sorters: SorterResult<ClientListItem> | SorterResult<ClientListItem>[]) => {
    const sorter = sorters as SorterResult<ClientListItem>

    resetSortOrder()
    if (sorter.field) {
      setOrder(sorter.field.toString(), sorter.order ?? null)
    }

    if (pagination.current && pagination.pageSize) {
      setPagination(pagination.current ?? 1, pagination.pageSize ?? 10, total)
    }
  }

  return (
    <>
      <SearchField onSearch={onSearchChanged} placeholder={intl.$t({ id: 'search_placeholder_clients' })} defaultValue={searchValue} />
      <Table
        dataSource={clients}
        columns={columns}
        pagination={{
          current: page,
          pageSize,
          total,
          hideOnSinglePage: true
        }}
        onChange={onChange}
        loading={isLoading}
        rowKey='id'
        locale={{
          emptyText: <TableEmptyState />
        }}
        showSorterTooltip={{ title: getSorterTooltipText(sortDirection) }}
        id='ClientTable'
      />
    </>
  )
}

export default ClientsList
