import { Input } from '@bdo/kitchensink'

type Props = {
  onSearch: (value: string) => void
  placeholder: string
  defaultValue: string
}

function SearchField(props: Readonly<Props>) {
  const { onSearch, placeholder, defaultValue } = props
  const { Search } = Input

  return (
    <div>
      <Search placeholder={placeholder} style={{ width: 400 }} className='ml-3 mb-2' allowClear onSearch={onSearch} defaultValue={defaultValue} />
    </div>
  )
}
export default SearchField
