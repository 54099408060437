/* eslint-disable array-callback-return */
import { RadioChangeEvent } from 'antd'
import { Form, Select, Radio, DatePicker, Input, InputNumber, Tooltip, Checkbox } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import { useIntl } from 'react-intl'
import { QuestionTypes, SharedQuestionsOptions } from 'services/Questionnaire/questionnaire.models'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { QuestionViewModel } from 'features/questionnaire/components/_models/QuestionViewModel'
import { OptionViewModel } from 'features/questionnaire/components/_models/OptionViewModel'
import { QuestionAnswer } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import useQuestionnaireAnswerStore, { useAnswer, useQuestion, useQuestionnaireAnswer } from 'features/clientDetails/components/questionAnswerZustandStore'
import { from } from 'linq-to-typescript'
import { CheckboxChangeEvent } from '@bdo/kitchensink/lib/components/checkbox/Checkbox'
import Icon from 'ui-components/Icon/Icon'
import dayjs from 'dayjs'
import styles from './Questions.module.scss'

type Props = {
  questionKey: string
}

function Questions(props: Readonly<Props>) {
  const { questionKey } = props
  const intl = useIntl()
  const [form] = Form.useForm()
  const { Option } = Select
  const question = useQuestion(questionKey)
  const answer = useAnswer(questionKey)
  const answers = useQuestionnaireAnswer()
  const dateFormat = 'YYYY-MM-DD'
  const multiplication = question?.multiplicationFactor ?? 1
  const templatesNumber = multiplication && question && question.generatedQuestions.length / multiplication
  const updateAnswer = useQuestionnaireAnswerStore((state) => state.updateAnswer)
  const updateQuestion = useQuestionnaireAnswerStore((state) => state.updateQuestion)
  const setVisibilityAndValidateGeneratedQuestions = useQuestionnaireAnswerStore((state) => state.setVisibilityAndValidateGeneratedQuestions)
  const isReadOnly = useQuestionnaireAnswerStore((state) => state.isReadOnly)
  const [questionGeneratorNumbersList, setQuestionGeneratorNumbersList] = useState<number[]>([])
  const [selectedMultiplicationNumber, setSelectedMultiplicationNumber] = useState<number>()
  const [generatedQuestions, setGeneratedQuestions] = useState<QuestionViewModel[]>([])
  const isNoneOptionEnabled = from(question.options).any((x) => x.isNoneOption === true)
  const noneOption = question.options.find((option) => option.isNoneOption === true)
  const [prevCheckedValues, setPrevCheckedValues] = useState<string[]>(() => {
    if (noneOption?.id && noneOption.isDefault) {
      return [noneOption.id]
    }
    return []
  })
  const formRef = useRef(null)

  const checkRadioButtonValue = () => {
    if (
      question?.type === QuestionTypes.Text ||
      question?.type === QuestionTypes.Number ||
      question?.type === QuestionTypes.Date ||
      question?.type === QuestionTypes.Multiplied ||
      question.type === QuestionTypes.Boolean
    )
      return []

    if (answer?.id === undefined) {
      const defaultOption = question?.options.find((option) => option.isDefault === true)
      if (defaultOption?.id) {
        return [defaultOption.id]
      }
    }
    if (answer?.text.toString().includes('|')) {
      return answer?.text.toString().split('|')
    }
    if (answer?.text.length !== 0) return [answer?.text.toString()]

    return []
  }

  const [radioButtonValue, setRadioButtonValue] = useState<(string | undefined)[]>(checkRadioButtonValue)

  useEffect(() => {
    if (!question) {
      return
    }

    if (form.getFieldValue(question.key) !== '') {
      form.validateFields([question.key])
    }

    if (question.isDefaultOptionEnabled && answer?.id === undefined) {
      const defaultOption = question.options.find((option: OptionViewModel) => option.isDefault === true)
      if ((question.type === QuestionTypes.Radio || question.type === QuestionTypes.Checkbox) && defaultOption !== undefined && defaultOption.id !== undefined) {
        setRadioButtonValue([defaultOption.id])
      }
      form.setFields([{ name: question.key, value: defaultOption?.isDefault }])
    }

    if (question.type === QuestionTypes.Multiplied && answer?.text !== '') {
      form.setFields([{ name: question.key, value: answer?.text }])
      const selectedNumber = Number(answer?.text)
      setSelectedMultiplicationNumber(selectedNumber)
    }
  }, [answer])

  useEffect(() => {
    if (!question || templatesNumber === 0) {
      return
    }

    if (selectedMultiplicationNumber === undefined) {
      return
    }

    const generatedQuestionsNew = question.generatedQuestions.slice(0, templatesNumber * selectedMultiplicationNumber)
    const hiddenGeneratedQuestions = question.generatedQuestions.slice(generatedQuestionsNew.length, question.generatedQuestions.length)

    setGeneratorToDefaultValue(question)

    generatedQuestionsNew.forEach((q) => {
      setGeneratorToDefaultValue(q)
    })

    setGeneratedQuestions(generatedQuestionsNew)
    setVisibilityAndValidateGeneratedQuestions(question, selectedMultiplicationNumber)

    removeAnswersFromHiddenGeneratedQuestions(hiddenGeneratedQuestions)
  }, [selectedMultiplicationNumber])

  useEffect(() => {
    if (!question || question.type !== QuestionTypes.Boolean) {
      return
    }

    if (answer.id === undefined && answer.text === '') {
      updateAnswer({ ...answer, text: question.defaultValue ?? '' })
      form.setFields([{ name: 'Boolean', value: question.defaultValue === 'true' }])
    } else {
      form.setFields([{ name: 'Boolean', value: answer.text === 'true' }])
    }
  }, [question])

  function setGeneratorToDefaultValue(targetQuestion: QuestionViewModel) {
    if (targetQuestion.type !== QuestionTypes.Multiplied) {
      return
    }
    const questionAnswer = answers.find((a) => a.questionId === targetQuestion.id)

    if (questionAnswer?.text === '') {
      updateAnswer({ ...questionAnswer, text: '0' })
    }
  }

  const onChange = (_value?: string | string[]) => {
    if (!question) {
      return
    }

    let value: string | number | string[] = _value === undefined || null ? '' : _value

    if (question.type === QuestionTypes.Number) {
      value = form.getFieldValue(question.key) ?? ''
    }

    if (question.type === QuestionTypes.Multiplied) {
      const newNumber = Number(_value)
      setSelectedMultiplicationNumber(newNumber)
    }

    if (answer !== undefined && answer !== null) {
      const newAnswer = { ...answer, text: value.toString() ?? form.getFieldValue(question.key) }

      form
        .validateFields([question.key, 'CheckBox', 'RadioGroup', 'Dropdown'])
        .then(() => {
          question.hasValidationErrors = false
        })
        .catch((e) => {
          question.hasValidationErrors = e.errorFields.length > 0 || question.isDisplay
        })
        .finally(() => {
          updateQuestion(question)
          updateAnswer(newAnswer)
          if (question.sharedQuestionsOptions.length !== 0) {
            updateDisplayStatusForSharedQuestions(question, newAnswer)
          }
        })
    }
  }

  const onChangeCheckBox = (checkedValues: string[]) => {
    setPrevCheckedValues(checkedValues)

    if (isNoneOptionEnabled) {
      const isNoneOptionSelected = checkedValues.find((optionId) => optionId === noneOption?.id)
      const wasPreviouslyNoneOptionSelected = prevCheckedValues.find((optionId) => optionId === noneOption?.id)

      if (!wasPreviouslyNoneOptionSelected && !isNoneOptionSelected) {
        setAnswersForCheckbox(checkedValues)
      } else if (!wasPreviouslyNoneOptionSelected && isNoneOptionSelected) {
        form.setFields([{ name: 'CheckBox', value: noneOption?.id }])
        setRadioButtonValue([noneOption?.id])
        onChange(noneOption?.id)
      } else if (wasPreviouslyNoneOptionSelected) {
        const newCheckedValues = checkedValues.filter((optionId) => optionId !== noneOption?.id)
        setAnswersForCheckbox(newCheckedValues)
        setPrevCheckedValues(newCheckedValues)
      }
    } else {
      setAnswersForCheckbox(checkedValues)
    }
  }

  function setAnswersForCheckbox(allCheckedValues: string[]) {
    form.setFields([{ name: 'CheckBox', value: allCheckedValues }])
    setRadioButtonValue(allCheckedValues)
    onChange(allCheckedValues.join('|'))
  }

  const onChangeRadioButton = (e: RadioChangeEvent) => {
    setRadioButtonValue(e.target.value)
    onChange(e.target.value)
  }

  const getDate = () => {
    if (answer && answer.text !== '') return dayjs(answer.text, dateFormat)

    return ''
  }

  const getInitialValueDropDown = () => {
    const initValue = answer && question?.options.find((option) => option.id?.includes(answer?.text))?.text
    if (answer && answer.text !== '') return initValue
    return ''
  }

  const generateNumbersList = (): void => {
    const maxNumber = question?.multiplicationFactor ?? 0
    const numbers = [...Array(maxNumber).keys()].map((i) => i + 1)
    numbers.unshift(0)

    setQuestionGeneratorNumbersList(numbers)
  }

  function updateDisplayStatusForSharedQuestions(questionParam: QuestionViewModel, newAnswer: QuestionAnswer): void {
    questionParam.sharedQuestions.forEach((gq: QuestionViewModel) => {
      const qwm = gq
      const answerToSharedQuestion = answers.find((a) => a.questionId === qwm.id)
      const isDisplay =
        questionParam.isDisplay &&
        questionParam.sharedQuestionsOptions?.filter((sqo: SharedQuestionsOptions) => qwm.id === sqo.sharedQuestionId && newAnswer.text.includes(sqo.optionId)).length !== 0
      const hasValidationErrors = qwm.isMandatory && answerToSharedQuestion?.text === '' && isDisplay

      if (qwm.isDisplay !== isDisplay || qwm.hasValidationErrors !== hasValidationErrors) {
        qwm.isDisplay = isDisplay
        qwm.hasValidationErrors = hasValidationErrors
        updateQuestion(qwm)

        updateVisibleSharedQuestionsWithCheckOfQuestionType(qwm, answerToSharedQuestion)
      }

      updateHiddenSharedQuestionsWithCheckOfDefaultOptions(qwm, answerToSharedQuestion)

      if (answerToSharedQuestion) {
        updateDisplayStatusForSharedQuestions(qwm, answerToSharedQuestion)
      }
    })
  }

  function updateVisibleSharedQuestionsWithCheckOfQuestionType(sharedQuestionWithType: QuestionViewModel, answerToSharedQuestion: QuestionAnswer | undefined) {
    const defaultOption = sharedQuestionWithType.options.find((option) => option.isDefault === true)

    if (answerToSharedQuestion && sharedQuestionWithType.type === QuestionTypes.Multiplied) {
      updateAnswer({ ...answerToSharedQuestion, text: '0' })
      removeAnswersFromHiddenGeneratedQuestions(sharedQuestionWithType.generatedQuestions)
    } else if (answerToSharedQuestion && sharedQuestionWithType.type !== QuestionTypes.Multiplied) {
      updateAnswer({ ...answerToSharedQuestion, text: sharedQuestionWithType.isDefaultOptionEnabled ? defaultOption?.id ?? '' : '' })
    }
  }

  function updateHiddenSharedQuestionsWithCheckOfDefaultOptions(sharedQuestionWithDefault: QuestionViewModel, answerToSharedQuestion: QuestionAnswer | undefined) {
    if (sharedQuestionWithDefault.isDisplay || !answerToSharedQuestion || sharedQuestionWithDefault.type === QuestionTypes.Multiplied) {
      return
    }
    const defaultOption = sharedQuestionWithDefault.options.find((option) => option.isDefault === true)

    if (!sharedQuestionWithDefault.isDefaultOptionEnabled) {
      updateAnswer({ ...answerToSharedQuestion, text: '' })
    } else if (sharedQuestionWithDefault.isDefaultOptionEnabled) {
      updateAnswer({ ...answerToSharedQuestion, text: sharedQuestionWithDefault.isDefaultOptionEnabled ? defaultOption?.id ?? '' : '' })
    }
  }

  function removeAnswersFromHiddenGeneratedQuestions(hiddenGeneratedQuestions: QuestionViewModel[]) {
    hiddenGeneratedQuestions.forEach((q) => {
      const hiddenAnswer = answers.find((a) => a.questionId === q.id)

      if (hiddenAnswer && hiddenAnswer.text !== '') {
        updateAnswer({ ...hiddenAnswer, text: '' })
        removeAnswersFromHiddenGeneratedQuestions(q.generatedQuestions)
      }
    })
  }

  const filterSharedQuestions = question?.sharedQuestionsOptions?.filter((sqo: SharedQuestionsOptions) => answer?.text.includes(sqo.optionId))
  const uniqueSharedQuestions = [...new Map(filterSharedQuestions?.map((item) => [item.sharedQuestionId, item])).values()]
  const dropDownOptions = question?.options.map((option) => ({ label: option.text, value: option.id ?? '' }))

  const onChangeBooleanQuestion = (e: CheckboxChangeEvent) => {
    form.setFields([{ name: 'Boolean', value: e.target.checked }])
    const booleanAnswer = e.target.checked.toString()
    updateAnswer({ ...answer, text: booleanAnswer })
  }

  return (
    <div>
      <Form ref={formRef} form={form} layout='vertical'>
        <div className={clsx(styles.questionWrapper, 'flex', 'alignItems', 'questionsToAnswer')}>
          <div className={styles.field}>
            {question?.type === QuestionTypes.Text && (
              <Form.Item
                name={question.key}
                label={question.text}
                rules={[
                  {
                    required: question.isMandatory,
                    message: translate('forms_required_field')
                  },
                  {
                    max: question.maxNumberOfCharacters,
                    message: translate('forms_too_many_characters')
                  }
                ]}
                initialValue={answer?.text}
              >
                <Input
                  onBlur={(e) => onChange(e.target.value)}
                  placeholder={intl.$t({ id: 'forms_placeholder_no_characters' }, { value: `${question.maxNumberOfCharacters}` })}
                  showCount
                  disabled={isReadOnly}
                  maxLength={question.maxNumberOfCharacters}
                />
              </Form.Item>
            )}
            {question?.type === QuestionTypes.Number && (
              <Form.Item
                name={question.key}
                label={question.text}
                rules={[
                  {
                    required: question.isMandatory,
                    message: translate('forms_required_field')
                  }
                ]}
                initialValue={answer?.text}
              >
                <InputNumber
                  type='number'
                  stringMode
                  value={answer?.text}
                  onBlur={() => onChange()}
                  placeholder={intl.$t({ id: 'form_placeholder_number' }, { value: '164' })}
                  maxLength={164}
                  disabled={isReadOnly}
                />
              </Form.Item>
            )}
            {question?.type === QuestionTypes.Date && (
              <Form.Item
                name={question.key}
                label={question.text}
                rules={[
                  {
                    required: question.isMandatory,
                    message: translate('forms_required_field')
                  }
                ]}
                initialValue={getDate()}
              >
                <DatePicker onChange={(_date, dateString) => onChange(dateString)} placeholder={intl.$t({ id: 'form_placeholder_date' }, { value: '164' })} disabled={isReadOnly} />
              </Form.Item>
            )}
            {question?.type === QuestionTypes.Dropdown && (
              <Form.Item
                name='Dropdown'
                label={question.text}
                rules={[
                  {
                    required: question.isMandatory,
                    message: translate('forms_required_field')
                  }
                ]}
                initialValue={getInitialValueDropDown()}
              >
                <Select placeholder={intl.$t({ id: 'form_select' })} onChange={onChange} disabled={isReadOnly} options={dropDownOptions} />
              </Form.Item>
            )}
            {question?.type === QuestionTypes.Radio && (
              <Form.Item
                name='RadioGroup'
                label={question.text}
                tooltip={question.tip}
                rules={[
                  {
                    required: question.isMandatory,
                    message: translate('forms_required_field')
                  }
                ]}
                initialValue={radioButtonValue[0]}
              >
                <Radio.Group name={question.key} onChange={onChangeRadioButton} value={radioButtonValue} disabled={isReadOnly}>
                  {question.options.map((option) => (
                    <Radio key={option.id} value={option.id} className={styles.radioButton}>
                      {option.text}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            )}
            {question?.type === QuestionTypes.Checkbox && (
              <Form.Item
                name='CheckBox'
                tooltip={question.tip}
                label={question.text}
                rules={[
                  {
                    required: question.isMandatory,
                    message: translate('forms_required_field')
                  }
                ]}
                initialValue={radioButtonValue}
              >
                <Checkbox.Group
                  className={clsx(isNoneOptionEnabled && styles.checkBoxGroup, 'checkBoxRef')}
                  options={question.options.map((o) => ({ label: o.text, value: o.id ?? '' }))}
                  onChange={onChangeCheckBox}
                  disabled={isReadOnly}
                />
              </Form.Item>
            )}
            {question?.type === QuestionTypes.Multiplied && (
              <Form.Item
                name={question.key}
                label={question.text}
                rules={[{ required: question.isMandatory, message: translate('forms_required_field') }]}
                initialValue={answer?.text}
              >
                <Select placeholder={intl.$t({ id: 'form_select' })} onChange={onChange} onFocus={generateNumbersList} disabled={isReadOnly}>
                  {questionGeneratorNumbersList.map((item: number) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {question?.type === QuestionTypes.Boolean && (
              <span className={styles.booleanWrapper}>
                <Form.Item name='Boolean' className={styles.booleanQuestion} valuePropName='checked'>
                  <Checkbox onChange={onChangeBooleanQuestion} disabled={isReadOnly}>
                    {question.text}
                  </Checkbox>
                </Form.Item>
                {question.tip && (
                  <div className={styles.hintBoolean}>
                    <Tooltip title={question.tip} mouseEnterDelay={0.1} placement='right'>
                      <p>
                        <Icon type='Unknown' className={styles.hintIcon} />
                      </p>
                    </Tooltip>
                  </div>
                )}
              </span>
            )}
          </div>
          {question?.tip && question.type !== QuestionTypes.Checkbox && question.type !== QuestionTypes.Radio && question.type !== QuestionTypes.Boolean && (
            <div className={styles.hint}>
              <Tooltip title={question.tip} mouseEnterDelay={0.1} placement='right'>
                <p>
                  <Icon type='Unknown' className={styles.hintIcon} />
                </p>
              </Tooltip>
            </div>
          )}
        </div>
      </Form>
      {uniqueSharedQuestions.map((sqo: SharedQuestionsOptions) => (
        <Questions key={sqo.sharedQuestionId} questionKey={sqo.sharedQuestionId} />
      ))}
      {generatedQuestions.map((gq: QuestionViewModel, index: number) => (
        <div key={gq.key} className={styles.generatedQuestion}>
          {templatesNumber > 0 && index % templatesNumber === 0 && <div className={styles.number}>{index / templatesNumber + 1}</div>}
          <Questions questionKey={gq.key} />
        </div>
      ))}
    </div>
  )
}

export default Questions
