import { getIconClassName } from '@uifabric/styling'
import { CSSProperties } from 'react'

type Props = {
  type: string
  className?: string
  style?: CSSProperties
  dataTestId?: string
}

export default function Icon(props: Readonly<Props>) {
  const { type, className, style, dataTestId } = props

  return <i style={style} className={[getIconClassName(type), className].join(' ')} data-testid={dataTestId} />
}
