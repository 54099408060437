import { OptionViewModel } from 'features/questionnaire/components/_models/OptionViewModel'
import { QuestionViewModel } from 'features/questionnaire/components/_models/QuestionViewModel'
import { QuestionTypes } from 'services/Questionnaire/questionnaire.models'
import { v4 as uuid } from 'uuid'

// eslint-disable-next-line import/prefer-default-export
export function createOption(): OptionViewModel {
  const optionKey = uuid()
  return {
    hasErrors: false,
    hasValidationErrors: true,
    isDefault: false,
    isNoneOption: false,
    key: optionKey,
    text: ''
  }
}

export function createQuestion(): QuestionViewModel {
  const id = uuid()
  return {
    key: id,
    text: '',
    isMandatory: false,
    type: QuestionTypes.Text,
    tip: '',
    isOpen: false,
    maxNumberOfCharacters: 164,
    hasErrors: false,
    options: [],
    hasValidationErrors: false,
    isDisplay: false,
    questionsTemplate: [],
    isQuestionTemplate: false,
    generatedQuestions: [],
    sharedQuestions: [],
    isSharedQuestion: false,
    sharedQuestionsOptions: []
  }
}
