/* eslint-disable react/require-default-props */
import { memo, useState } from 'react'
import translate from 'i18n/translate'
import { Button, Form, Modal, Input } from '@bdo/kitchensink'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import { GroupContentViewModel } from 'features/questionnaire/components/_models/GroupContentViewModel'
import { v4 as uuidv4 } from 'uuid'
import { useFormFieldIsReadOnly, useQuestionnaireGroups, useQuestionnaireStore } from 'features/questionnaire/questionaryZustandStore'
import { from } from 'linq-to-typescript'
import styles from './AddGroupModal.module.scss'

type AddGroupModalProps = {
  buttonType: 'default' | 'primary' | 'success' | 'warning' | 'danger' | 'link'
}

function AddGroupModal(props: Readonly<AddGroupModalProps>) {
  const { buttonType } = props
  const groups = useQuestionnaireGroups()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isFilled, setIsFilled] = useState(false)
  const [form] = Form.useForm()
  const intl = useIntl()
  const formFieldIsReadOnly = useFormFieldIsReadOnly()
  const addGroup = useQuestionnaireStore((state) => state.addGroup)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    const groupName = form.getFieldValue('name')
    const groupUiid = uuidv4()
    const columnUiid = uuidv4()
    const newGroup: GroupContentViewModel = {
      name: groupName.trim(),
      columns: [{ key: columnUiid, questions: [], isDeleted: false, name: '', hasErrors: false, hasValidationErrors: false }],
      numberOfColumns: 1,
      key: groupUiid,
      hasErrors: false,
      hasValidationErrors: false
    }

    const groupNameExists = from(groups).any((x) => x.name.toLowerCase() === newGroup.name.toLowerCase())

    if (groupNameExists) {
      form.setFields([{ name: 'name', errors: [intl.$t({ id: 'message_error_group_name_exists' })], touched: true }])
    } else {
      addGroup(newGroup)
      form.resetFields()
      setIsModalOpen(false)
      setIsFilled(false)
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    form.resetFields()
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFilled(true)
    if (e.target.value === '') {
      setIsFilled(false)
    }
  }

  return (
    <div className={styles.addGroupModalWrapper}>
      <Button type={buttonType ?? 'default'} icon={<PlusCircleOutlined />} onClick={showModal} data-testid='question_group_add_new' disabled={formFieldIsReadOnly}>
        {' '}
        {translate('question_group_add_new')}
      </Button>
      <Modal
        title={translate('question_group_add_new')}
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form.submit}
        okText={translate('button_add')}
        cancelText={translate('button_cancel')}
        okButtonProps={{ disabled: !isFilled }}
      >
        <Form name='create_question_group_form' form={form} layout='vertical' onFinish={handleOk} data-testid='question_group_modal'>
          <Form.Item name='name' label={translate('question_group_name')} rules={[{ required: true, message: translate('forms_required_field') }]}>
            <Input placeholder={intl.$t({ id: 'forms_placeholder_no_characters' }, { value: '164' })} maxLength={164} showCount onChange={handleOnChange} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default memo(AddGroupModal)
