import React from 'react'
import { usePublishedQuestionnaireData } from 'features/clients/clientZustandStore'
import clsx from 'clsx'
import ModificationInfo from './ModificationInfo'
import styles from './infoBox.module.scss'

type Props = {
  appId: number
}

export default function TwinfieldInfo(props: Readonly<Props>) {
  const { appId } = props
  const publishedQuestionnaireData = usePublishedQuestionnaireData(appId)

  return (
    <div className={styles.mainInfoWrapper}>
      <div className={styles.boxLabel}>Exporting files info</div>
      <div className={clsx(styles.infoWrapper, 'flex', 'alignItems')}>
        <ModificationInfo modificationInfo={publishedQuestionnaireData} />
      </div>
    </div>
  )
}
