import { onboardingToolApiConfig } from 'app.config'
import axios from 'axios'
import queryString from 'query-string'

export type Condition = {
  accountId: number
  questionnaireId: string
  groupId: string | undefined
  questionId: string | undefined
  optionIds: string[]
  range?: GeneratorRange
  parentQuestionId?: string
  booleanValue?: boolean
}

export type GeneratorRange = {
  min: number
  max: number
}

const accountConditionsUri = `${onboardingToolApiConfig.uri}/accountConditions`

export async function saveConditions(accountId: number, condition: Condition): Promise<Condition> {
  const postResponse = await axios.post<Condition>(accountConditionsUri, {
    accountId,
    optionIds: condition.optionIds,
    questionId: condition.questionId,
    groupId: condition.groupId,
    questionnaireId: condition.questionnaireId,
    range: condition.range,
    parentQuestionId: condition.parentQuestionId,
    booleanValue: condition.booleanValue as boolean
  })
  return postResponse.data
}

export async function updateConditions(accountId: number, condition: Condition): Promise<Condition> {
  const postResponse = await axios.put<Condition>(accountConditionsUri, {
    accountId,
    optionIds: condition.optionIds,
    questionId: condition.questionId,
    groupId: condition.groupId,
    questionnaireId: condition.questionnaireId,
    range: condition.range,
    parentQuestionId: condition.parentQuestionId,
    booleanValue: condition.booleanValue as boolean
  })
  return postResponse.data
}

export async function getCondition(accountId: number) {
  const conditionUri = `${accountConditionsUri}/${accountId}`
  const getResponse = await axios.get<Condition[]>(conditionUri)
  return getResponse.data
}

export async function deleteCondition(accountId: number, questionnaireId: string) {
  const deleteUri = queryString.stringifyUrl({ url: accountConditionsUri, query: { accountId, questionnaireId } })
  return axios.delete(deleteUri)
}
