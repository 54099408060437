import axios, { AxiosHeaders, AxiosResponse, AxiosResponseHeaders } from 'axios'
import { QuestionnaireViewModel } from 'pages/_models/QuestionnaireViewModel'
import { GroupContentViewModel } from 'features/questionnaire/components/_models/GroupContentViewModel'
import { from } from 'linq-to-typescript'
import queryString from 'query-string'
import { GeneratorRange } from 'services/accountCondition.service'
import buildQuery from 'odata-query'
import { Questionnaire, CreateQuestionnaire, QuestionnaireStatuses, Group, Question, QuestionTypes, QuestionAccountConnection, Apps } from './questionnaire.models'
import { QuestionViewModel } from '../../features/questionnaire/components/_models/QuestionViewModel'
import { onboardingToolApiConfig } from '../../app.config'

const questionnairesUri = `${onboardingToolApiConfig.uri}/questionnaires`

export async function createQuestionnaire(model: CreateQuestionnaire): Promise<Questionnaire> {
  const questionnaireRequest = {
    name: model?.name?.trim(),
    status: 'Draft',
    groups: [],
    service: model.service,
    application: model.application
  }
  const response = await axios.post<Questionnaire>(questionnairesUri, questionnaireRequest)

  return response.data
}

function mapToViewModel(data: Questionnaire) {
  const groupsVM = data.groups?.map((g) => mapToGroupViewModel(g))

  const questionnaireVM: QuestionnaireViewModel = {
    ...data,
    groups: groupsVM,
    hasErrors: false,
    hasValidationErrors: false
  }

  return questionnaireVM
}

function mapToGroupViewModel(group: Group): GroupContentViewModel {
  const groupViewModel: GroupContentViewModel = {
    ...group,
    hasErrors: false,
    hasValidationErrors: false,
    key: group.id ?? '',
    columns: group.columns?.map((c) => ({
      ...c,
      key: c.id ?? '',
      hasErrors: false,
      hasValidationErrors: false,
      questions: c.questions?.map((q) => mapToQuestionViewModel(q))
    }))
  }

  return groupViewModel
}

function mapToQuestionViewModel(question: Question): QuestionViewModel {
  const result: QuestionViewModel = {
    ...question,
    hasErrors: false,
    hasValidationErrors: false,
    isDisplay: false,
    isOpen: false,
    key: question.id ?? '',
    isDefaultOptionEnabled: from(question.options || []).any((x) => x.isDefault === true),
    questionsTemplate: question.questionsTemplate?.map((tq) => ({
      ...mapToQuestionViewModel(tq),
      isQuestionTemplate: true
    })),
    isQuestionTemplate: false,
    generatedQuestions: question.generatedQuestions?.map((gq) => ({
      ...mapToQuestionViewModel(gq),
      isDisplay: false
    })),
    sharedQuestions: question.sharedQuestions.map((q) => ({
      ...mapToQuestionViewModel(q),
      isSharedQuestion: true
    })),
    sharedQuestionsOptions: question.sharedQuestionsOptions,
    isSharedQuestion: false,
    options: question.options?.map((option) => ({
      ...option,
      hasErrors: false,
      hasValidationErrors: false,
      key: option.id ?? '',
      isDefault: option.isDefault
    }))
  }

  return result
}

export async function getQuestionnaires(name?: string, status?: QuestionnaireStatuses[], application?: Apps): Promise<Questionnaire[]> {
  const orderBy = 'modifiedDate desc'
  let filterValue = ''

  if (name) {
    const filter = [{ Status: { in: status } }, { Application: { eq: application } }, { Name: name }]
    filterValue = buildQuery({ filter, orderBy })
  } else {
    filterValue = buildQuery({ filter: { Status: { in: status }, Application: { eq: application } }, orderBy })
  }

  const getUri = `${questionnairesUri}${filterValue}`
  const response = await axios.get<Questionnaire[]>(getUri)
  return response.data
}

export async function saveQuestionnaire(questionnaire: Questionnaire): Promise<QuestionnaireViewModel> {
  const response = await axios.put<Questionnaire>(questionnairesUri, questionnaire)
  return mapToViewModel(response.data)
}

export async function getQuestionnaire(id: string): Promise<QuestionnaireViewModel> {
  const uri = `${questionnairesUri}/${id}`
  const response = await axios.get<Questionnaire>(uri)
  const { data } = response
  return mapToViewModel(data)
}

export async function cloneQuestionnaire(id: string, newName: string): Promise<QuestionnaireViewModel> {
  const duplicateUri = `${questionnairesUri}/${id}/duplicate`
  const postResponse = await axios.post<Questionnaire>(duplicateUri, { duplicateName: newName })

  return mapToViewModel(postResponse.data)
}

export async function archiveQuestionnaire(id: string) {
  const postResponse = await axios.post<Questionnaire>(`${questionnairesUri}/${id}/archive`)

  return mapToViewModel(postResponse.data)
}

export async function getGroups(questionnaireId: string): Promise<GroupContentViewModel[]> {
  const groupUri = `${onboardingToolApiConfig.uri}/groups`
  const getUri = queryString.stringifyUrl({
    url: groupUri,
    query: { questionnaireId }
  })
  const getResponse = await axios.get<Group[]>(getUri)
  const result = getResponse.data.map((g) => mapToGroupViewModel(g))
  return result
}

export async function getQuestions(groupId: string, types: QuestionTypes[]): Promise<QuestionViewModel[]> {
  const questionsUri = `${onboardingToolApiConfig.uri}/questions`
  const getUri = queryString.stringifyUrl({ url: questionsUri, query: { groupId, typesFilter: types } }, { arrayFormat: 'comma' })
  const getResponse = await axios.get<Question[]>(getUri)
  const result = getResponse.data?.map((q) => mapToQuestionViewModel(q))
  return result
}

export async function getChildQuestions(parentQuestionId: string): Promise<QuestionViewModel> {
  const uri = `${onboardingToolApiConfig.uri}/questions/${parentQuestionId}`
  const getResponse = await axios.get(uri)
  const result = mapToQuestionViewModel(getResponse.data)
  return result
}

export async function requestQuestionnairePublication(id: string) {
  const uri = `${questionnairesUri}/${id}/publication`
  await axios.post<Questionnaire[]>(uri)
}

type Condition = {
  accountId: string
  questionnaireId: string
  questionId: string
  optionId: string
  range: GeneratorRange
}

type Description = {
  questionnaireId: string
  questionId: string
  descriptionTemplateId: string
  accountId: string
}

export async function getAccountConnection(filter: { groupId?: string; questionId?: string[]; optionId?: string[] }): Promise<QuestionAccountConnection[]> {
  const stringifyOptions = { skipEmptyString: true, skipNull: true }

  const accountConditionsUri = queryString.stringifyUrl({ url: `${onboardingToolApiConfig.uri}/accountConditions`, query: filter }, stringifyOptions)
  const conditionsPromise = axios.get<Condition[]>(accountConditionsUri)

  const accountDescriptionsUri = queryString.stringifyUrl(
    {
      url: `${onboardingToolApiConfig.uri}/accountDescriptions`,
      query: filter
    },
    stringifyOptions
  )
  const isOptionSent = filter.optionId !== undefined
  const descriptionsPromise = isOptionSent ? Promise.resolve<AxiosResponse>(createEmptyResponse()) : axios.get(accountDescriptionsUri)

  const [conditions, descriptions] = await Promise.all([conditionsPromise, descriptionsPromise])

  const conditionConnections: QuestionAccountConnection[] = conditions.data.map((x: Condition) => ({
    accountId: x.accountId,
    questionnaireId: x.questionnaireId,
    questionId: x.questionId,
    connectedObjectId: x.optionId,
    range: x.range
  }))

  const descriptionConnections = descriptions.data.map((x: Description) => ({
    questionnaireId: x.questionnaireId,
    questionId: x.questionId,
    descriptionTemplateId: x.descriptionTemplateId,
    accountId: x.accountId
  }))

  return conditionConnections.concat(descriptionConnections)
}

function createEmptyResponse(): AxiosResponse {
  const headers: AxiosResponseHeaders = new AxiosHeaders()

  return {
    data: [],
    status: 200,
    statusText: 'OK',
    headers: {},
    config: {
      headers
    }
  }
}
