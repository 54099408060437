import useQuestionnaireAnswerStore from 'features/clientDetails/components/questionAnswerZustandStore'
import useClientStore from 'features/clients/clientZustandStore'
import { useState, useRef, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { processingFinished } from 'services/Client/clientProcessedQuestionnaireStatus.service'
import { AnswerQuestionnaireStatus } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import AsyncNotification, { AsyncMessageType, NotificationType } from 'ui-components/AsyncNotification/AsyncNotification'
import SignalRContext from 'utils/SignalRContextHelper/SignalRContextHelper'

export enum SignalRSubscriptions {
  FilesExport = 'FilesExport',
  QuestionnairePublished = 'QuestionnairePublished'
}

export type Props = {
  subscription: SignalRSubscriptions
}

function SignalRReceiver(props: Readonly<Props>) {
  const { subscription } = props
  const [messages, setMessages] = useState<AsyncMessageType[]>([])
  const updateStatusOfCurrentInstance = useClientStore((state) => state.updateStatusOfCurrentInstance)
  const removeIdOfAppWhenRequestSent = useClientStore((state) => state.removeIdOfAppWhenRequestSent)
  const clientId = useClientStore((state) => state.client.id)
  const previousMessage = useRef<AsyncMessageType[]>([])
  const intl = useIntl()
  const { appId } = useParams<string>()
  const applicationId = parseInt(appId ?? '0', 10)
  const updateIsArchived = useQuestionnaireAnswerStore((state) => state.updateIsArchived)
  const defaultErrorMessge = intl.$t({ id: `signalR_default_error_message` })

  useEffect(() => {
    previousMessage.current = messages
  }, [messages])

  SignalRContext.useSignalREffect(
    subscription,
    (message) => {
      const duplicatedEmailErrorGP =
        message.data.errors.length > 0 && message.data.errors[0].code === 'GlobalPortal_DuplicatedEmailAddress' ? JSON.parse(message.data.errors[0].message) : []

      function getDescription(signalRMessageStatus: NotificationType) {
        switch (signalRMessageStatus) {
          case NotificationType.Success:
          case NotificationType.Warning:
          case NotificationType.Info:
            return intl.$t({ id: `signalR_${message.messageCode}_message` }, { clientId: `${message.data.id}` })

          case NotificationType.Error:
            return message.data.errors.length > 0 && message.data.errors[0].code === 'GlobalPortal_DuplicatedEmailAddress'
              ? intl.$t(
                  { defaultMessage: defaultErrorMessge, id: `signalR_${message.data.errors[0].code}` },
                  {
                    duplicatedEmailError: `${duplicatedEmailErrorGP[0].email}`,
                    groups: `${duplicatedEmailErrorGP[0].groups.join(', ')}`
                  }
                )
              : intl.$t(
                  { defaultMessage: defaultErrorMessge, id: `signalR_${message.data.errors[0].code}` },
                  {
                    email: `${message.data.errors[0].message}`
                  }
                )

          default:
            return ''
        }
      }

      const signalRMessage: AsyncMessageType = {
        id: message.id,
        title: intl.$t({ id: `signalR_${message.messageCode}_title` }),
        description: getDescription(message.status),
        status: message.status,
        data: { appId: message.data.applicationId, clientId: message.data.id, instanceId: message.data.instanceId }
      }

      if (clientId.toString() === message.data.id) {
        updateStatusOfCurrentInstance(signalRMessage.status === 'success' ? AnswerQuestionnaireStatus.Completed : AnswerQuestionnaireStatus.Ready)
      }

      if (signalRMessage.data.appId === applicationId) {
        updateIsArchived(true)
        setMessages([...messages, signalRMessage])
      }

      if (subscription !== SignalRSubscriptions.QuestionnairePublished) {
        setMessages([...messages, signalRMessage])
      }

      removeIdOfAppWhenRequestSent(Number(signalRMessage.data.clientId), signalRMessage.data.appId, signalRMessage.data.instanceId)
      processingFinished(Number(signalRMessage.data.clientId), signalRMessage.data.appId, signalRMessage.data.instanceId)
    },
    []
  )

  const cleanUpMessageOnClose = (id: string) => {
    setMessages(previousMessage.current.filter((message) => message.id !== id))
  }

  return (
    <>
      {messages.map((message) => (
        <AsyncNotification key={message.id} message={message} cleanUpMessageOnClose={cleanUpMessageOnClose} />
      ))}
    </>
  )
}
export default SignalRReceiver
