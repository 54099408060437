import { Button, Message } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { v4 as uuidv4 } from 'uuid'
import { Operators } from 'utils/FilterHelper/filter-helper'
import { useGeneralLedgerStore } from 'features/generalLedger/generalLedgerStore/generalLedgerStore'
import { accountFilterableFields, getAccounts } from 'services/generalLedger.service'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import Icon from 'ui-components/Icon/Icon'
import styles from './FilterControls.module.scss'

type Props = {
  toggleFilters: () => void
  isFilterOpen: boolean
}

function FilterControls(props: Readonly<Props>) {
  const { toggleFilters, isFilterOpen } = props
  const [isPrimary, setIsPrimary] = useState<boolean>(false)

  const setPagination = useGeneralLedgerStore((state) => state.setPagination)
  const setAccounts = useGeneralLedgerStore((state) => state.setAccounts)
  const applyFilters = useGeneralLedgerStore((state) => state.applyFilters)
  const addFilter = useGeneralLedgerStore((state) => state.addFilter)
  const clearFilters = useGeneralLedgerStore((state) => state.clearFilters)
  const setOrder = useGeneralLedgerStore((state) => state.setOrder)
  const pageSize = useGeneralLedgerStore((state) => state.pageSize)
  const filters = useGeneralLedgerStore((state) => state.filters)
  const page = useGeneralLedgerStore((state) => state.page)
  const isDirty = useGeneralLedgerStore((state) => state.isDirty)
  const sortField = useGeneralLedgerStore((state) => state.sortField)
  const sortDirection = useGeneralLedgerStore((state) => state.sortDirection)
  const intl = useIntl()
  const sortOrder = sortDirection === 'ascend' ? `${sortField}` : `${sortField} desc`

  const handleFilterToggle = () => {
    toggleFilters()
    if (filters.length === 0) {
      const newFilter = { id: uuidv4(), field: accountFilterableFields[0], operator: Operators.Equal, value: '', hasErrors: false }
      addFilter(newFilter)
    }
  }

  useEffect(() => {
    setIsPrimary(isFilterOpen)
  }, [isFilterOpen])

  const onClearFilters = () => {
    clearFilters()
    getAccounts(page, pageSize, [], sortOrder)
      .then((data) => {
        applyFilters()
        setAccounts(data.rows)
        setPagination(1, pageSize, data.total)
        setOrder('accountNumber', 'ascend')
        Message.success(intl.$t({ id: 'user_message_filters_updated' }), 5)
      })
      .catch((e) => {
        LoggerHelper(e)
      })
  }

  return (
    <div className={clsx('flex', 'alignItems')}>
      <Button type={isPrimary ? 'primary' : 'default'} className={clsx('flex', 'alignItems')} onClick={handleFilterToggle}>
        <Icon type='Filter' className={clsx(isPrimary && styles.filterIcon, 'mr-1')} />
        {translate('filter')}
      </Button>
      <button type='button' className={clsx('textButton', 'ml-3', isPrimary ? 'hidden' : 'visible', isDirty ? 'visible' : 'hidden')} disabled={!isDirty} onClick={onClearFilters}>
        {translate('button_clear')}
      </button>
    </div>
  )
}

export default FilterControls
