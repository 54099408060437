/* eslint-disable no-bitwise */
import translate from 'i18n/translate'
import { Table } from '@bdo/kitchensink'
import { TablePaginationConfig, TableProps, SorterResult } from '@bdo/kitchensink/lib/components/table/Table'
import { Link } from 'react-router-dom'
import TableEmptyState from 'ui-components/TableEmptyState/TableEmptyState'
import { useGeneralLedgerStore } from 'features/generalLedger/generalLedgerStore/generalLedgerStore'
import { LedgerAccount } from 'services/generalLedger.service'
import getSorterTooltipText from 'utils/TooltipTextHelper/tooltipTextHelper'
import Icon from 'ui-components/Icon/Icon'
import DeleteAction from '../DeleteAction/DeleteAction'

type Props = {
  isLoading: boolean
}

function AccountsList(props: Readonly<Props>) {
  const { isLoading } = props

  const setPagination = useGeneralLedgerStore((state) => state.setPagination)
  const setOrder = useGeneralLedgerStore((state) => state.setOrder)
  const resetSortOrder = useGeneralLedgerStore((state) => state.resetSortOrder)
  const sortField = useGeneralLedgerStore((state) => state.sortField)
  const sortDirection = useGeneralLedgerStore((state) => state.sortDirection)
  const total = useGeneralLedgerStore((state) => state.total)
  const pageSize = useGeneralLedgerStore((state) => state.pageSize)
  const accounts = useGeneralLedgerStore((state) => state.accounts)
  const page = useGeneralLedgerStore((state) => state.page)

  const columns = [
    {
      title: translate('account_number'),
      dataIndex: 'accountNumber',
      key: 'accountNumber',
      sortOrder: sortField === 'accountNumber' ? sortDirection : undefined,
      sorter: true,
      render: (accountNumber: string, item: LedgerAccount) => <Link to={`${item.id}/account`}>{accountNumber}</Link>,
      width: 150
    },
    {
      title: translate('account_type'),
      dataIndex: 'type',
      sorter: true,
      sortOrder: sortField === 'type' ? sortDirection : undefined,
      key: 'type'
    },
    {
      title: translate('account_description_nl'),
      dataIndex: 'dutchDescription',
      key: 'dutchDescription',
      sorter: true,
      sortOrder: sortField === 'dutchDescription' ? sortDirection : undefined
    },
    {
      title: translate('account_description_en'),
      dataIndex: 'englishDescription',
      key: 'englishDescription',
      sorter: true,
      sortOrder: sortField === 'englishDescription' ? sortDirection : undefined
    },
    {
      title: translate('account_isActive'),
      dataIndex: 'alwaysOn',
      key: 'alwaysOn',
      sorter: true,
      sortOrder: sortField === 'alwaysOn' ? sortDirection : undefined,
      render: (alwaysOn: boolean) => <p>{alwaysOn ? <Icon type='Accept' /> : ''}</p>,
      width: 135
    },
    {
      title: translate('actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 80,
      render: (accountNumber: string, item: LedgerAccount) => <DeleteAction number={item.accountNumber} id={item.id} />
    }
  ]

  const onTableChange: TableProps<LedgerAccount>['onChange'] = (pagination: TablePaginationConfig, _, sorters: SorterResult<LedgerAccount> | SorterResult<LedgerAccount>[]) => {
    const sorter = sorters as SorterResult<LedgerAccount>

    resetSortOrder()
    if (sorter.field) {
      setOrder(sorter.field?.toString(), sorter.order ?? null)
    }

    if (pagination.current && pagination.pageSize && pagination.total) {
      setPagination(pagination.current, pagination.pageSize, total)
    }
  }

  return (
    <Table
      dataSource={accounts}
      columns={columns}
      rowKey='accountNumber'
      pagination={{
        current: page,
        pageSize,
        total,
        hideOnSinglePage: true
      }}
      onChange={onTableChange}
      locale={{
        emptyText: <TableEmptyState />
      }}
      showSorterTooltip={{ title: getSorterTooltipText(sortDirection) }}
      loading={isLoading}
      id='ClientTable'
    />
  )
}

export default AccountsList
