import { Form, Modal, Input, Tooltip } from '@bdo/kitchensink'
import { memo, useEffect, useState } from 'react'
import { toKey } from 'utils/StringHelper/string-helper'
import translate from 'i18n/translate'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { useFormFieldIsReadOnly } from 'features/questionnaire/questionaryZustandStore'
import Icon from 'ui-components/Icon/Icon'
import styles from './EditValueModal.module.scss'

type EditValueModalProps = {
  currentValue: string | undefined
  onConfirmEnteredValue: (newValue: string) => string | Promise<string>
  title: string
  fieldName: string
  okText: string
  cancelText: string
  buttonText?: string
  closePopover: () => void
  simpleButton?: boolean
}

function EditValueModal(props: Readonly<EditValueModalProps>) {
  const { fieldName, currentValue, okText, title, onConfirmEnteredValue, cancelText, buttonText, closePopover, simpleButton } = props
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isFilled, setIsFilled] = useState(false)
  const [form] = Form.useForm()
  const intl = useIntl()
  const formFieldIsReadOnly = useFormFieldIsReadOnly()

  useEffect(() => {
    form.setFields([{ name: toKey(fieldName), value: currentValue }])
  }, [])

  const handleOk = async () => {
    if (onConfirmEnteredValue) {
      const newValue: string = form.getFieldValue(toKey(fieldName))
      const existingValueError = await onConfirmEnteredValue(newValue)

      if (existingValueError) {
        form.setFields([{ name: toKey(fieldName), errors: [existingValueError], touched: true }])
      } else {
        setIsModalOpen(false)
      }
    }
    setIsFilled(false)
  }

  const handleOnClick = () => {
    setIsModalOpen(true)
    form.setFields([{ name: toKey(fieldName), value: currentValue }])
    closePopover()
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFilled(true)
    if (e.target.value === '') {
      setIsFilled(false)
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setIsFilled(false)

    form.resetFields()
    form.setFields([{ name: toKey(fieldName), value: currentValue }])
  }

  return (
    <>
      <Tooltip title={translate('button_edit')} mouseEnterDelay={0.5}>
        <button
          type='button'
          onClick={handleOnClick}
          className={clsx(simpleButton ? styles.popupTrigger : styles.buttonTrigger, 'flex', 'alignItems')}
          data-testid='editIcon'
          disabled={formFieldIsReadOnly}
        >
          <Icon type='Edit' className={styles.editIcon} />
          <span className={styles.buttonText}>{buttonText}</span>
        </button>
      </Tooltip>

      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form.submit}
        title={title}
        okText={okText}
        cancelText={cancelText}
        forceRender
        okButtonProps={{ disabled: !isFilled }}
      >
        <Form name='create_question_group_form' form={form} layout='vertical' onFinish={handleOk}>
          <Form.Item name={toKey(fieldName)} label={fieldName} rules={[{ required: true, message: translate('forms_required_field') }]}>
            <Input maxLength={164} showCount placeholder={intl.$t({ id: 'forms_placeholder_no_characters' }, { value: '164' })} onChange={handleOnChange} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default memo(EditValueModal)
