import { Form, Select, Radio, Input } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import { useIntl } from 'react-intl'
import { useGeneralLedgerStore, useFilterById } from 'features/generalLedger/generalLedgerStore/generalLedgerStore'
import { useEffect } from 'react'
import clsx from 'clsx'
import { AccountFields, accountFilterableFields } from 'services/generalLedger.service'
import { FilterItem, Operators } from 'utils/FilterHelper/filter-helper'
import Icon from 'ui-components/Icon/Icon'
import styles from './FilterRow.module.scss'

type Props = {
  id: string
}

function FilterRow(props: Readonly<Props>) {
  const { id } = props
  const [form] = Form.useForm()
  const intl = useIntl()
  const RadioGroup = Radio.Group
  const { Option } = Select
  const { filters, updateFilter, removeFilter } = useGeneralLedgerStore()
  const filter = useFilterById(id)

  useEffect(() => {
    form.setFields([
      { name: 'filteredInfo', value: filter.field.fieldName || null },
      { name: 'operator', value: filter.operator || null },
      { name: 'value', value: filter.value }
    ])
  }, [])

  const onFilterValueChanged = async () => {
    const field = accountFilterableFields.find((x) => x.fieldName === form.getFieldValue('filteredInfo'))
    if (!field) {
      return
    }

    let newFilter: FilterItem = {
      id,
      field,
      operator: form.getFieldValue('operator') || '',
      value: form.getFieldValue('value').trim(),
      hasErrors: false
    }

    form
      .validateFields(['value'])
      .catch((e) => {
        newFilter = { ...newFilter, hasErrors: e.errorFields?.length > 0 }
      })
      .finally(() => {
        updateFilter(newFilter)
      })
  }

  const onRemoveFilter = () => {
    removeFilter(id)
  }

  const onFieldChanged = () => {
    const selectedField = accountFilterableFields.find((x) => x.fieldName === form.getFieldValue('filteredInfo'))

    if (selectedField) {
      let value = ''
      if (selectedField.type === 'boolean') {
        value = 'true'
      }
      const newFilter: FilterItem = {
        id,
        field: selectedField,
        operator: form.getFieldValue('operator') || '',
        value: value || '',
        hasErrors: false
      }

      updateFilter(newFilter)
      form.setFields([
        { name: 'operator', value: Operators.Equal },
        { name: 'value', value }
      ])
    }
  }

  return (
    <Form name='filters' form={form} layout='vertical' onValuesChange={onFilterValueChanged} className='refFilterForm'>
      <div className={clsx(styles.formRowWrapper, 'flex', 'alignItems')}>
        <Form.Item name='filteredInfo' className={styles.filter}>
          <Select placeholder={intl.$t({ id: 'filter_select_placeholder' })} onSelect={onFieldChanged} data-testid='accountData'>
            <Option value={AccountFields.AccountNumber}>{translate('account_number')}</Option>
            <Option value={AccountFields.DescriptionNL}>{translate('account_description_nl')}</Option>
            <Option value={AccountFields.DescriptionEN}>{translate('account_description_en')}</Option>
            <Option value={AccountFields.AlwaysOn}>{translate('account_isActive')}</Option>
          </Select>
        </Form.Item>
        <Form.Item name='operator' className={styles.operator} data-testid='operator'>
          <Select placeholder={intl.$t({ id: 'filter_condition_placeholder' })}>
            <Option value={Operators.Equal}>{translate('operator_equal')}</Option>
            <Option value={Operators.NotEqual}>{translate('operator_not_equal')}</Option>
            <Option value={Operators.GreaterThan} disabled={filter.field?.type !== 'number'}>
              {translate('operator_greater')}
            </Option>
            <Option value={Operators.GreaterOrEqual} disabled={filter.field?.type !== 'number'}>
              {translate('operator_greater_equal')}
            </Option>
            <Option value={Operators.LowerThan} disabled={filter.field?.type !== 'number'}>
              {translate('operator_lower_than')}
            </Option>
            <Option value={Operators.LowerOrEqual} disabled={filter.field?.type !== 'number'}>
              {translate('operator_lower_equal')}
            </Option>
            <Option value={Operators.StartsWith} disabled={filter.field?.type !== 'string'}>
              {translate('operator_startswith')}
            </Option>
            <Option value={Operators.Contains} disabled={filter.field?.type !== 'string'}>
              {translate('operator_contains')}
            </Option>
          </Select>
        </Form.Item>
        {filter.field?.type === 'string' && (
          <Form.Item name='value' className={styles.value} rules={[{ required: true, message: translate('forms_required_field') }]} data-testid='value'>
            <Input placeholder={intl.$t({ id: 'forms_placeholder_no_characters' }, { value: '164' })} maxLength={164} showCount />
          </Form.Item>
        )}
        {filter.field?.type === 'number' && (
          <Form.Item
            data-testid='value'
            className={styles.value}
            name='value'
            rules={[
              { min: 7, max: 7, message: translate('user_message_7_digits') },
              { pattern: /^\d*$/, message: translate('user_message_integer') },
              { required: true, message: translate('forms_required_field') }
            ]}
          >
            <Input step='1' min='0' placeholder={intl.$t({ id: 'forms_placeholder_account_number' }, { value: '7' })} maxLength={7} />
          </Form.Item>
        )}
        {filter.field?.type === 'boolean' && (
          <Form.Item name='value' className={clsx(styles.value)} data-testid='value'>
            <RadioGroup value={filter.value}>
              <Radio checked={filter.value === 'true'} value='true'>
                {translate('filter_value_yes')}
              </Radio>
              <Radio checked={filter.value === 'false'} value='false'>
                {translate('filter_value_no')}
              </Radio>
            </RadioGroup>
          </Form.Item>
        )}

        <button
          type='button'
          aria-label='button'
          onClick={onRemoveFilter}
          disabled={filters.length < 2}
          className={clsx(styles.removeFilterButton, 'iconButton')}
          data-testid='delete'
        >
          <Icon type='Delete' />
        </button>
      </div>
    </Form>
  )
}

export default FilterRow
