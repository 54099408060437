import { Spinner, Modal } from '@bdo/kitchensink'

type Props = {
  open: boolean
  text: string
}

function Loader(props: Readonly<Props>) {
  const { open, text } = props

  const contentStyle: React.CSSProperties = {
    background: 'rgba(0, 0, 0, 0.05)'
  }
  const content = <div style={contentStyle} />

  return (
    <Modal
      centered
      closable={false}
      footer={null}
      keyboard={false}
      maskClosable={false}
      open={open}
      width={280}
      className='loaderRef'
      styles={{
        body: {
          padding: '15%',
          textAlign: 'center'
        }
      }}
    >
      <Spinner tip={text} style={{ lineHeight: '19px', textWrap: 'nowrap' }}>
        {' '}
        {content}
      </Spinner>
    </Modal>
  )
}

export default Loader
