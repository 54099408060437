import translate from 'i18n/translate'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { Message, Tooltip, Modal } from '@bdo/kitchensink'
import { deleteTemplate as deleteTemplateRequest, getTemplates } from 'services/templates.service'
import { useTemplatesStore } from 'features/generalLedger/TemplatesStore/TemplatesStore'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import Icon from 'ui-components/Icon/Icon'
import styles from './TemplateDelete.module.scss'

type Props = {
  id: string
  name: string
}

function TemplateDelete(props: Readonly<Props>) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { id, name } = props
  const intl = useIntl()
  const { page, pageSize, setTemplates, setPagination } = useTemplatesStore()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    deleteTemplateRequest(id)
      .then(() => {
        getTemplates(page, pageSize)
          .then((data) => {
            setTemplates(data.rows)
            setPagination(page, pageSize, data.total)
          })
          .catch((error) => {
            LoggerHelper(error)
          })
        setIsModalOpen(false)
        Message.success(intl.$t({ id: 'user_message_template_deleted' }), 5)
      })
      .catch((error) => {
        Message.error(intl.$t({ id: 'user_message_template_deleted_error' }), 5)
        setIsModalOpen(false)
        LoggerHelper(error)
      })
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Tooltip title={translate('button_delete')} mouseEnterDelay={0.5}>
      <button type='button' aria-label='button' className={clsx(styles.deleteButton, 'iconButton')} onClick={showModal}>
        <Icon type='Delete' />
      </button>
      <Modal
        title={translate('description_template_delete')}
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        okText={translate('button_delete')}
        cancelText={translate('button_cancel')}
      >
        {translate('user_message_when_delete_template', { name })}
      </Modal>
    </Tooltip>
  )
}

export default TemplateDelete
